import React from "react";
import { PageTitles } from "../../_shared/shared.enums";
import useAnalytics from "../analytics/hooks/useAnalytics";
import useDocumentTitle from "../../_shared/hooks/useDocumentTitle";

export default function ReportIssue() {
  useAnalytics(["service", "report-issue"]);
  useDocumentTitle(PageTitles.ReportIssue);

  return (
    <div>
      <h1>Report an issue</h1>
      <p>
        Email:{" "}
        <a className="nhsuk-link" href="mailto:ravs.support@england.nhs.uk">
          ravs.support@england.nhs.uk
        </a>
      </p>
    </div>
  );
}
