import React from "react";
import { useNavigate } from "react-router-dom";
import "./ErrorPage.scss";
import useAnalytics from "../analytics/hooks/useAnalytics";
import useDocumentTitle from "../../_shared/hooks/useDocumentTitle";

const ErrorPage: React.FC = () => {
  const navigate = useNavigate();

  useAnalytics(["service", "error", "service-unavailable"]);
  useDocumentTitle("Error");

  const handleRetry = () => {
    navigate(-1);
  };

  return (
    <div
      className="nhsuk-width-container"
      role="main"
      aria-labelledby="error-page-title"
    >
      <main className="nhsuk-main-wrapper">
        <div className="nhsuk-grid-row">
          <div className="nhsuk-grid-column-full">
            <div
              className="nhsuk-panel nhsuk-panel--error"
              role="alert"
              aria-live="assertive"
            >
              <h1 className="nhsuk-panel__title" id="error-page-title">
                The service is unavailable
              </h1>
              <div className="nhsuk-panel__body nhsuk-panel__body--extra-space">
                There may be a problem with your internet connection, or a
                technical problem on our side.
              </div>
              <div className="nhsuk-panel--custom-outline">
                <div className="nhsuk-panel__body">
                  <strong className="nhsuk-panel__subheader">
                    What you can do:
                  </strong>
                  <ul className="nhsuk-list nhsuk-list--custom-bullet">
                    <li>Return to the start</li>
                    <li>
                      Try using a different internet connection if the one you
                      are using does not work
                    </li>
                  </ul>
                </div>
                <div className="nhsuk-action-link">
                  <button
                    className="nhsuk-button"
                    onClick={handleRetry}
                    aria-label="Try again"
                  >
                    Try again
                  </button>
                </div>
                <div className="nhsuk-panel__body">
                  If this does not work, you can see more options in our user
                  guidance.
                </div>
                <div className="nhsuk-panel__body">
                  If you urgently need to record a vaccination,{" "}
                  <a
                    className="nhsuk-link nhsuk-link--service"
                    href="https://guide.ravs.england.nhs.uk/files/record-a-vaccination.docx"
                    download
                    aria-label="Download our paper form"
                  >
                    download our paper form
                  </a>
                  .
                </div>
                <div className="nhsuk-panel__body nhsuk-panel__body--additional-space">
                  More advice in our user guidance
                </div>
                <div className="nhsuk-action-link">
                  <a
                    className="nhsuk-link nhsuk-link--service"
                    href="https://guide.ravs.england.nhs.uk/service-unavailable/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Get advice and guidance"
                  >
                    Get advice and guidance
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ErrorPage;
