import React, { useEffect, useState } from "react";
import { BackLink } from "nhsuk-react-components";
import OrganisationUserForm from "../../user-management/OrganisationUserForm";
import AccessControl from "../../../_shared/components/accessControl/AccessControl";
import { PolicyIds, RoleIds } from "../user.enums";
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import useDocumentTitle from "../../../_shared/hooks/useDocumentTitle";
import useAnalytics from "../../analytics/hooks/useAnalytics";
import { ManageUsersContext, matchRoleIdWithRole } from "./ManageUsersRoot";
import { Loading } from "../../../_shared/components/Loading";
import { AccountStatusUser } from "../user.models";
import userDbService from "../userDb.service";
import { useUser } from "../UserProvider";

const ManageUsersEditPage: React.FC = () => {
  const navigate = useNavigate();
  useDocumentTitle("Manage users");
  const { users, editUser, isLoading } = useOutletContext<ManageUsersContext>();
  const [accountStatusUser, setAccountStatusUser] =
    useState<AccountStatusUser>(null);
  const currentUser = useUser();

  const location = useLocation();
  const focusField = location.state?.focusField;

  useAnalytics(["service", "manage-users", "edit"]);

  const { id } = useParams();
  const user = users.find((u) => `${u.UserId}` === id);

  if (users.length > 0 && !user) {
    // If the user is not found, navigate back to the manage users page
    navigate("/manage-users", { replace: true });
  }

  let userFormValues = null;

  if (user) {
    userFormValues = {
      id: user.UserId,
      firstName: user.FirstName,
      lastName: user.LastName,
      email: user.Email.toLocaleLowerCase(),
      clinician: user.Clinical ? "Yes" : "No",
      permission: matchRoleIdWithRole(user.Roles),
    };
  }

  useEffect(() => {
    if (userFormValues?.id) {
      userDbService
        .accountStatusUser$(userFormValues.id.toString())
        .then((res) => {
          setAccountStatusUser(res);
        });
    }
  }, [userFormValues != null && userFormValues.id]);

  return (
    <AccessControl
      requiredRoles={[RoleIds.LeadAdministrator]}
      matchAllRoles={false}
    >
      <div className="nhsuk-grid-row">
        <BackLink asElement="button" onClick={() => navigate("..")}>
          Back
        </BackLink>
        {isLoading && <Loading />}
        {userFormValues && (
          <>
            <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--xl nhsuk-u-margin-bottom-7">
              <h1 className="nhsuk-fieldset__heading">
                {userFormValues.firstName} {userFormValues.lastName}
              </h1>
            </legend>

            <OrganisationUserForm
              userFormValues={userFormValues}
              initialFocusField={focusField}
              isEditing={true}
              users={users}
              onSubmit={editUser}
              canDeactivateUsers={
                accountStatusUser &&
                userFormValues.id &&
                currentUser.Policies.some(
                  (p) => p === PolicyIds.CanDeactivateUsers,
                )
              }
              isEditingSelf={
                accountStatusUser && userFormValues.id === currentUser.UserId
              }
              manyLeadAdminsExist={
                accountStatusUser && accountStatusUser.ManyLeadAdminsExist
              }
            />
          </>
        )}
      </div>
    </AccessControl>
  );
};

export default ManageUsersEditPage;
