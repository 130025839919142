import React from "react";
import { BackLink } from "nhsuk-react-components";
import OrganisationUserSummary from "../../user-management/OrganisationUserSummary";
import AccessControl from "../../../_shared/components/accessControl/AccessControl";
import { RoleIds } from "../user.enums";
import { useNavigate, useOutletContext } from "react-router-dom";
import useDocumentTitle from "../../../_shared/hooks/useDocumentTitle";
import useAnalytics from "../../analytics/hooks/useAnalytics";
import { ManageUsersContext } from "./ManageUsersRoot";

const pageTitle = "Check and add user";

const ManageUsersSummaryPage: React.FC = () => {
  const navigate = useNavigate();
  useDocumentTitle(pageTitle);
  const { userFormValues, addUser } = useOutletContext<ManageUsersContext>();

  useAnalytics(["service", "manage-users", "check"]);

  if (!userFormValues.firstName) {
    navigate("/manage-users");
  }

  return (
    <AccessControl
      requiredRoles={[RoleIds.LeadAdministrator]}
      matchAllRoles={false}
    >
      <div className="nhsuk-grid-row">
        <BackLink
          asElement="button"
          onClick={() => navigate("..", { relative: "path" })}
        >
          Back
        </BackLink>
        <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--xl nhsuk-u-margin-bottom-7">
          <h1 className="nhsuk-fieldset__heading">{pageTitle}</h1>
        </legend>

        <OrganisationUserSummary
          userFormValues={userFormValues}
          onConfirmAndSend={() => addUser(userFormValues)}
          onChangeField={(fieldName) => {
            navigate("/manage-users/add", {
              state: { focusField: fieldName },
            });
          }}
        />
      </div>
    </AccessControl>
  );
};

export default ManageUsersSummaryPage;
