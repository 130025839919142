export function GenderName(genderId) {
  switch (genderId) {
    case 1:
      return "Male";

    case 2:
      return "Female";

    case 3:
      return "Other";

    case 4:
      return "Unknown";

    default:
      return genderId;
  }
}
