import * as React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  OrganisationHierarchy,
  SiteVaccines,
  SiteVaccinesData,
} from "./site-vaccines.models";
import {
  SiteVaccinesAnalyticsPageNames,
  SiteVaccinesPageTitles,
  SiteVaccinesPaths,
} from "./site-vaccines.enums";
import useAnalytics from "../analytics/hooks/useAnalytics";
import useDocumentTitle from "../../_shared/hooks/useDocumentTitle";
import { useUser } from "../user/UserProvider";
import { BatchDto } from "../batch/batch.models";
import siteVaccinesService from "./site-vaccines.service";
import { NhsdOrganisation, Option } from "../../_shared/shared.models";
import { Loading } from "../../_shared/components/Loading";

export default function SiteVaccinesList() {
  useAnalytics(["service", SiteVaccinesAnalyticsPageNames.PrimaryCategory]);
  useDocumentTitle(SiteVaccinesPageTitles.SiteVaccinesList);

  const user = useUser();
  const location = useLocation();
  const navigate = useNavigate();

  const optionsStateData =
    location && location.state ? (location.state[0] as any) : null;
  const siteVaccinesStateData =
    location && location.state
      ? (location.state[1] as SiteVaccines[])
      : ([] as SiteVaccines[]);

  const [options, setOptions] = React.useState(optionsStateData);
  const [siteVaccines, setSiteVaccines] = React.useState(siteVaccinesStateData);
  const [optionsLoading, setOptionsLoading] = React.useState(true);
  const [loading, setLoading] = React.useState(true);
  const [siteVaccinesLoading, setSiteVaccinesLoading] = React.useState(true);
  const [siteVaccinesData, setSiteVaccinesData] = React.useState(
    null as SiteVaccinesData,
  );

  React.useEffect(() => {
    let siteVaccinesDataProcess: SiteVaccinesData = {
      Sites: [],
      SiteVaccines: [],
      SiteVaccinePrograms: [],
    };

    if (user) {
      try {
        setLoading(true);

        const getOptions = async () => {
          setOptionsLoading(true);
          let newOptions = await siteVaccinesService
            .options$(user?.OrganisationId)
            .finally(() => setOptionsLoading(false));
          if (newOptions !== options) {
            setOptions(newOptions);
          }
        };
        if (options === null) {
          getOptions();
        }

        const getSiteVaccines = async () => {
          setSiteVaccinesLoading(true);
          const result = await siteVaccinesService
            .getAllSiteVaccines$()
            .finally(() => setSiteVaccinesLoading(false));
          if (result.SiteVaccines?.length !== siteVaccines?.length) {
            setSiteVaccines(result.SitesVaccines);
          }
        };
        if (siteVaccinesLoading === true && options === null) {
          getSiteVaccines();
        }
      } finally {
        setLoading(false);
      }

      if (siteVaccines && loading === false && options) {
        let sites: string[] = [];

        for (let si of options?.Sites as Option[]) {
          Object.values(siteVaccines).filter((sv, i) => {
            if (sv.Site === si.Name) {
              sites?.push(si.Name);
            }
          });
        }

        let distinctSites = sites.filter(
          (site, i, arr) => arr.findIndex((s) => s === site) === i,
        );

        let siteVaccineList: SiteVaccines[] = [];
        for (let distinctSite of distinctSites) {
          Object.values(siteVaccines).filter((sv, i) => {
            if (sv.Site === distinctSite) {
              siteVaccineList.push(sv);
            }
          });
        }

        let siteVaccinePrograms: string[] = [];
        for (let distinctSite of distinctSites) {
          Object.values(siteVaccines).filter((sv, i) => {
            if (sv.Site === distinctSite) {
              for (let vp of options?.VaccinePrograms) {
                if (sv.VaccineProgram === vp.Name) {
                  siteVaccinePrograms.push(vp.Name);
                }
              }
            }
          });
        }

        let distinctVaccinePrograms = siteVaccinePrograms?.filter(
          (vprog, i, arr) => arr.findIndex((vp) => vp === vprog) === i,
        );

        siteVaccinesDataProcess.Sites = distinctSites;
        siteVaccinesDataProcess.SiteVaccines = siteVaccineList;
        siteVaccinesDataProcess.SiteVaccinePrograms = distinctVaccinePrograms;

        setSiteVaccinesData(siteVaccinesDataProcess);
      }
    }
  }, [
    loading,
    location,
    options,
    optionsLoading,
    siteVaccines,
    siteVaccinesLoading,
    user,
  ]);

  const processSiteVaccinesData = (filter: boolean, site?: string) => {
    let siteVaccinesDataProcess: SiteVaccinesData = {
      Sites: [],
      SiteVaccines: [],
      SiteVaccinePrograms: [],
    };

    if (siteVaccines && loading === false && options) {
      let siteVaccinesFilter = filter
        ? siteVaccines?.filter((sv) => sv.Site === site)
        : siteVaccines;
      let sites: string[] = [];

      for (let si of options?.Sites) {
        Object.values(siteVaccinesFilter).filter((sv, i) => {
          if (sv.Site === si.Name) {
            sites.push(si.Name);
          }
        });
      }

      let distinctSites = sites?.filter(
        (site, i, arr) => arr.findIndex((s) => s === site) === i,
      );

      let siteVaccineList: SiteVaccines[] = [];
      for (let distinctSite of distinctSites) {
        Object.values(siteVaccinesFilter).filter((sv, i) => {
          if (sv.Site === distinctSite) {
            siteVaccineList.push(sv);
          }
        });
      }

      let siteVaccinePrograms: string[] = [];
      for (let distinctSite of distinctSites) {
        Object.values(siteVaccinesFilter).filter((sv, i) => {
          if (sv.Site === distinctSite) {
            for (let vp of options?.VaccinePrograms) {
              if (sv.VaccineProgram === vp.Name) {
                siteVaccinePrograms.push(vp.Name);
              }
            }
          }
        });
      }

      let distinctVaccinePrograms = siteVaccinePrograms?.filter(
        (vprog, i, arr) => arr.findIndex((vp) => vp === vprog) === i,
      );

      siteVaccinesDataProcess.Sites = distinctSites;
      siteVaccinesDataProcess.SiteVaccines = siteVaccineList;
      siteVaccinesDataProcess.SiteVaccinePrograms = distinctVaccinePrograms;
    }

    return siteVaccinesDataProcess;
  };

  const filterSitesVaccinesBySite = React.useMemo(
    () => (event) => {
      event.preventDefault();

      const site = event.target.value;

      if (site !== "-1") {
        setSiteVaccinesData(processSiteVaccinesData(true, site));
      } else {
        setSiteVaccinesData(processSiteVaccinesData(false));
      }
    },
    [processSiteVaccinesData],
  );

  const handleAddVaccineClick = async () => {
    setSiteVaccinesLoading(true);

    try {
      const result =
        await siteVaccinesService.isUserAssignedToParentOrganisation$();

      if (result.IsParent) {
        navigate(SiteVaccinesPaths.AddSite, {
          state: [
            options,
            options?.Sites,
            siteVaccines,
            { OrganisationHierarchy: { ...result } },
          ],
        });
      } else {
        await handleNonParentOrganisation(result);
      }
    } catch (error) {
      console.error("Error handling vaccine addition:", error);
    } finally {
      setSiteVaccinesLoading(false);
    }
  };

  const handleNonParentOrganisation = async (result: OrganisationHierarchy) => {
    const userSites = siteVaccines as SiteVaccines[];

    try {
      const orgDetails = await siteVaccinesService.nhsdOrganisation$(
        result.OdsCode,
      );
      const site = createSiteObject(
        orgDetails,
        result.OdsCode,
        result.IsParent,
      );

      navigate(SiteVaccinesPaths.AddVaccine, {
        state: [options, userSites, siteVaccines, site],
      });
    } catch (error) {
      console.error("Error fetching organisation details:", error);
    }
  };

  const createSiteObject = (
    orgDetails: NhsdOrganisation,
    odsCode: string,
    isParent: boolean,
  ) => ({
    Site: orgDetails?.Name,
    SiteField: `${odsCode} ${orgDetails?.Name}, ${orgDetails?.PostCode}`,
    Code: orgDetails?.OrgId,
    AddrLn1: orgDetails?.AddressLine1,
    AddrLn2: orgDetails?.AddressLine2,
    Town: orgDetails?.Town,
    County: orgDetails?.County,
    PostCode: orgDetails?.PostCode,
    IsParent: isParent,
  });

  return (
    <>
      {loading === false && options ? (
        <>
          <div className="nhsuk-grid-row mt-3">
            <div className="nhsuk-grid-column-full">
              <fieldset className="nhsuk-fieldset">
                <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--xl">
                  <h1 className="nhsuk-fieldset__heading">Vaccines</h1>
                </legend>

                <div className="nhsuk-hint" id="select-site-hint">
                  Add and edit your vaccines
                </div>
              </fieldset>
              <div>
                <button
                  onClick={handleAddVaccineClick}
                  className="nhsuk-button"
                >
                  Add vaccine
                </button>
              </div>

              {options && options?.Sites?.length >= 2 && (
                <div className="nhsuk-form-group">
                  <label key="siteId" htmlFor="SiteId" className="nhsuk-label">
                    Select site
                  </label>
                  <select
                    id="SiteId"
                    name="SiteId"
                    onChange={filterSitesVaccinesBySite}
                    className="nhsuk-select"
                  >
                    <option key="-1" value="-1">
                      All sites
                    </option>
                    {options?.Sites?.map((u, i) => (
                      <>
                        <option key={i} value={u?.Name}>
                          {" "}
                          {u?.Name}{" "}
                        </option>
                      </>
                    ))}
                  </select>
                </div>
              )}

              <>
                {siteVaccinesData ? (
                  <>
                    {siteVaccinesData?.Sites?.map((site, x) => (
                      <>
                        <div key={x}>
                          <h1>{site}</h1>

                          {options?.VaccinePrograms?.map((vp, c) => (
                            <div key={c}>
                              {siteVaccinesData?.SiteVaccinePrograms?.map(
                                (siteVaccineProgram, a) => (
                                  <div key={a}>
                                    <>
                                      {siteVaccineProgram === vp.Name &&
                                        siteVaccinesData?.SiteVaccines?.filter(
                                          (sv) =>
                                            sv.VaccineProgram === vp.Name &&
                                            sv.Site === site,
                                        )?.length > 0 && (
                                          <>
                                            <h2>{siteVaccineProgram}</h2>
                                            <>
                                              <table
                                                role="table"
                                                className="nhsuk-table-responsive"
                                              >
                                                <thead
                                                  role="rowgroup"
                                                  className="nhsuk-table__head"
                                                >
                                                  <tr role="row">
                                                    <th
                                                      role="columnheader"
                                                      className=""
                                                      scope="col"
                                                      style={{ width: "60%" }}
                                                    >
                                                      Vaccine product
                                                    </th>
                                                    <th
                                                      role="columnheader"
                                                      className=""
                                                      scope="col"
                                                      style={{ width: "20%" }}
                                                    >
                                                      Active batches
                                                    </th>
                                                    <th
                                                      role="columnheader"
                                                      className=""
                                                      scope="col"
                                                      style={{ width: "20%" }}
                                                    ></th>
                                                  </tr>
                                                </thead>

                                                {siteVaccinesData?.SiteVaccines?.map(
                                                  (siteVaccine, d) => (
                                                    <>
                                                      {siteVaccine.VaccineProgram ===
                                                        siteVaccineProgram &&
                                                        siteVaccine.Site ===
                                                          site && (
                                                          <>
                                                            <>
                                                              <tbody className="nhsuk-table__body">
                                                                <tr
                                                                  role="row"
                                                                  className="nhsuk-table__row"
                                                                >
                                                                  <td
                                                                    role="cell"
                                                                    className="nhsuk-table__cell"
                                                                  >
                                                                    <React.Fragment>
                                                                      <>
                                                                        <span className="nhsuk-table-responsive__heading">
                                                                          Vaccine
                                                                          product
                                                                        </span>
                                                                        {
                                                                          siteVaccine.Vaccine
                                                                        }
                                                                      </>
                                                                      <br></br>
                                                                    </React.Fragment>
                                                                  </td>

                                                                  <td
                                                                    role="cell"
                                                                    className="nhsuk-table__cell"
                                                                  >
                                                                    <React.Fragment>
                                                                      <>
                                                                        <span className="nhsuk-table-responsive__heading">
                                                                          Active
                                                                          batches
                                                                        </span>{" "}
                                                                        {
                                                                          siteVaccine.SiteVaccineBatches.filter(
                                                                            (
                                                                              svb,
                                                                            ) =>
                                                                              (!svb.DepletedDate ||
                                                                                (svb?.DepletedDate &&
                                                                                  new Date(
                                                                                    svb.DepletedDate,
                                                                                  ) >=
                                                                                    new Date(
                                                                                      new Date().setDate(
                                                                                        new Date().getDate() -
                                                                                          1,
                                                                                      ),
                                                                                    ))) &&
                                                                              new Date(
                                                                                svb.ExpiryDate,
                                                                              ) >
                                                                                new Date(
                                                                                  new Date().setDate(
                                                                                    new Date().getDate() -
                                                                                      1,
                                                                                  ),
                                                                                ),
                                                                          )
                                                                            .length
                                                                        }
                                                                      </>
                                                                      <br></br>
                                                                    </React.Fragment>
                                                                  </td>

                                                                  <td
                                                                    role="cell"
                                                                    className="nhsuk-table__cell text-end"
                                                                  >
                                                                    <Link
                                                                      className="nhsuk-back-link__link"
                                                                      to={{
                                                                        pathname:
                                                                          SiteVaccinesPaths.SiteVaccineBatchesList,
                                                                      }}
                                                                      state={[
                                                                        options,
                                                                        options?.Sites,
                                                                        siteVaccines,
                                                                        siteVaccine,
                                                                      ]}
                                                                    >
                                                                      View
                                                                      product
                                                                    </Link>
                                                                    <br></br>
                                                                    <br></br>
                                                                    <Link
                                                                      className="nhsuk-back-link__link"
                                                                      to={{
                                                                        pathname:
                                                                          SiteVaccinesPaths.AddBatch,
                                                                      }}
                                                                      state={[
                                                                        options,
                                                                        options?.Sites,
                                                                        siteVaccines,
                                                                        {
                                                                          Site: siteVaccine?.Site,
                                                                          Code: siteVaccine.Code,
                                                                          VaccineProgramId:
                                                                            siteVaccine?.VaccineProgramId,
                                                                          VaccineProgram:
                                                                            siteVaccine?.VaccineProgram,
                                                                          VaccineId:
                                                                            siteVaccine?.VaccineId,
                                                                          Vaccine:
                                                                            siteVaccine?.Vaccine,
                                                                        } as BatchDto,
                                                                        siteVaccine?.SiteVaccineBatches,
                                                                        {
                                                                          IsAddBatch:
                                                                            true,
                                                                          IsEditBatch:
                                                                            false,
                                                                          IsAddEditBatchFromSiteVaccinesList:
                                                                            true,
                                                                          IsAddEditBatchFromSiteBatchesList:
                                                                            false,
                                                                        },
                                                                        null,
                                                                        siteVaccine,
                                                                      ]}
                                                                    >
                                                                      Add batch
                                                                    </Link>
                                                                  </td>
                                                                </tr>
                                                              </tbody>
                                                            </>
                                                            <>
                                                              <br></br>
                                                            </>
                                                          </>
                                                        )}
                                                    </>
                                                  ),
                                                )}
                                              </table>
                                            </>
                                          </>
                                        )}
                                    </>
                                  </div>
                                ),
                              )}
                            </div>
                          ))}
                        </div>
                      </>
                    ))}
                  </>
                ) : (
                  <Loading />
                )}
              </>
            </div>
          </div>
        </>
      ) : (
        <Loading />
      )}
    </>
  );
}
