import React, { FC, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

import userManagementService from "../../../_shared/services/user-management/userManagement.service";
import { Clinician } from "../../vaccination/vaccination.models";
import { User } from "../user.models";
import { useUser } from "../UserProvider";
import { useNavigate } from "react-router-dom";
import { roleList } from "../user.functions";
import { matchRoleWithRoleId } from "../../../_shared/shared.functions";
import { AccountStatusIds } from "../user.enums";
import userDbService from "../userDb.service";
import { PageType } from "../../../_shared/shared.enums";

const fetchClinicians = async (
  organisationId: number,
): Promise<Clinician[]> => {
  try {
    return await userManagementService.getClinicians$(organisationId);
  } catch (error) {
    console.error("Failed to fetch clinicians");
    return [];
  }
};

const fetchUsers = async (organisationId: number): Promise<User[]> => {
  try {
    return await userManagementService.getUsers$(organisationId);
  } catch (error) {
    console.error("Failed to fetch users");
    return [];
  }
};

const fetchDeactivatedUserCount = async (): Promise<number> => {
  try {
    return await userDbService.deactivatedUserCount$();
  } catch (error) {
    console.error("Failed to fetch deactivated user count");
    return 0;
  }
};

export interface UserFormValues {
  id?: number;
  firstName: string;
  lastName: string;
  email: string;
  clinician: string;
  permission: string;
}

export const initialValues: UserFormValues = {
  firstName: "",
  lastName: "",
  email: "",
  clinician: "",
  permission: "",
};

export interface ManageUsersContext {
  users: User[];
  addUser: (values: UserFormValues) => Promise<void>;
  editUser: (values: UserFormValues) => Promise<void>;
  isLoading: boolean;
  userFormValues: UserFormValues;
  setUserFormValues: React.Dispatch<React.SetStateAction<UserFormValues>>;
  deactivatedUserCount: number;
}

export const matchRoleIdWithRole = (roleIds: string[]): string | undefined => {
  const privilegeOrder = ["2", "1", "3"]; //Lead admin > Admin > Recoder
  const roles = roleList();

  // Filter the roles based on the provided roleIds and sort them by privilege
  const matchedRoles = roles
    .filter((role) => roleIds.includes(role.Id))
    .sort(
      (a, b) => privilegeOrder.indexOf(a.Id) - privilegeOrder.indexOf(b.Id),
    );

  // Return the name of the role with the highest privilege
  return matchedRoles.length > 0 ? matchedRoles[0].Name : undefined;
};

const fullClinicianRoleCheck = (Roles: number[]): boolean => {
  return Roles.includes(1) && Roles.includes(2) && Roles.includes(3);
};

const userIsClinician = (user: User, clinicians: Clinician[]): boolean => {
  if (user.Clinical) {
    return true;
  }

  if (
    clinicians &&
    clinicians.some(
      (clinician) =>
        clinician.Email.toLocaleLowerCase() ===
          user.Email.toLocaleLowerCase() &&
        !clinician.IsDeleted &&
        (clinician.IsFullClinician || fullClinicianRoleCheck(clinician.Roles)),
    )
  ) {
    return true;
  }

  return false;
};

const ManageUsersRoot: FC = () => {
  const currentUser = useUser();
  const navigate = useNavigate();
  const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userFormValues, setUserFormValues] =
    useState<UserFormValues>(initialValues);
  const [deactivatedUserCount, setDeactivatedUserCount] = useState<number>(0);
  const [refreshCount, setRefreshCount] = useState<number>(0);

  const formValuesToUser = (values: UserFormValues, mode: PageType): User => {
    const { id, firstName, lastName, email, permission, clinician } = values;

    return {
      UserId: id,
      FirstName: firstName,
      LastName: lastName,
      Email: email,
      Roles: [matchRoleWithRoleId(permission)],
      OrganisationId: currentUser?.OrganisationId,
      AccountStatusId: mode === PageType.Add ? AccountStatusIds.Pending : null,
      Clinical: clinician.toLowerCase() === "yes",
      LastLoginDate: null,
    };
  };

  useEffect(() => {
    if (currentUser && currentUser.OrganisationId) {
      setIsLoading(true);
      Promise.all([
        fetchClinicians(currentUser.OrganisationId),
        fetchUsers(currentUser.OrganisationId),
      ])
        .then(([clinicians, users]) => {
          const usersWithClinicalStatus = users.map((user) => {
            // Use the clinicians list to update users with their clinical status
            return {
              ...user,
              Clinical: userIsClinician(user, clinicians),
            };
          });

          setUsers(usersWithClinicalStatus);
          setIsLoading(false);
        })
        .catch(() => {
          navigate("/error");
        });

      fetchDeactivatedUserCount().then((count) => {
        setDeactivatedUserCount(count);
      });
    }
  }, [currentUser, refreshCount]);

  const addUser = async (userFormValues: UserFormValues) => {
    const user = formValuesToUser(userFormValues, PageType.Add);

    try {
      const res = await userManagementService.addOrEdit$(user, PageType.Add);
      if (res) {
        setUserFormValues(initialValues);
        navigate("/manage-users");
        setRefreshCount(refreshCount + 1);
      }
    } catch (error) {
      console.error("Error adding or editing user:", error);
    }
  };

  const editUser = async (values: UserFormValues) => {
    const updatedUser = formValuesToUser(values, PageType.Edit);

    try {
      await userManagementService
        .addOrEdit$(updatedUser, PageType.Edit)
        .then((res) => {
          const updatedUsers = users.map((user) => {
            if (user.UserId === updatedUser.UserId) {
              updatedUser.AccountStatusId = user.AccountStatusId;
              updatedUser.LastLoginDate = user.LastLoginDate;

              return { ...user, ...updatedUser };
            }
            return user;
          });

          setUsers(updatedUsers);
          navigate("/manage-users");
        });
    } catch (error) {
      console.error("Error adding or editing user:", error);
    }
  };

  const context = {
    users,
    isLoading,
    userFormValues,
    setUserFormValues,
    addUser,
    editUser,
    deactivatedUserCount,
  } satisfies ManageUsersContext;

  return <Outlet context={context} />;
};

export default ManageUsersRoot;
