import { appAxios } from "../../../_shared/axios";

export interface GenerateReportParams {
  siteIds?: number[];
  vaccinationPrograms?: number[];
  excludeData: string[];
  startDate: string;
  endDate: string;
}

export interface SiteName {
  Name: string;
  SiteId: number;
}

export interface VaccineProgramName {
  Name: string;
  VaccineProgramId: number;
}

export class ReportsService {
  private basePath = "api/Reports/";

  async getAllSites$(organisationId: number): Promise<SiteName[]> {
    return (
      await appAxios.get(
        `${this.basePath}GetAllSites?organisationId=${organisationId}`,
      )
    ).data;
  }

  async getAllVaccinationPrograms$(): Promise<VaccineProgramName[]> {
    return (await appAxios.get(`${this.basePath}GetAllVaccinationPrograms`))
      .data;
  }

  async generateReport(params: GenerateReportParams): Promise<string> {
    const response = await appAxios.get(`${this.basePath}GetReport`, {
      responseType: "arraybuffer",
      params,
      paramsSerializer: {
        indexes: null,
      },
    });

    return window.URL.createObjectURL(
      new Blob([response.data], { type: "application/octet-stream" }),
    );
  }

  downloadReport(blobUrl: string) {
    try {
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = "RAVs-Vaccination-Report.csv";
      document.body.appendChild(link);
      link.click();
      link.remove();
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.log("Error getting report", error);
    }
  }

  static get instance() {
    return reportsService;
  }
}

const reportsService = new ReportsService();

export default reportsService;
