import React, { useEffect, useState } from "react";
import { Button, SummaryList } from "nhsuk-react-components";
import { ReportsPages, SelectedDates } from "../user/pages/Reports";
import { Loading } from "../../_shared/components/Loading";
import { FormateDate } from "../../_shared/shared.functions";

interface SummaryField {
  key: string;
  value: string;
  field: string;
}

const ReportSummary: React.FC<{
  handlePageChange: (isForward: boolean) => void;
  dates: SelectedDates;
  vaccines: string[];
  sites: string[];
  data: string[];
  setCurrentPage: React.Dispatch<React.SetStateAction<ReportsPages>>;
  generateReport: () => Promise<void>;
}> = ({
  handlePageChange,
  dates,
  vaccines,
  sites,
  data,
  setCurrentPage,
  generateReport,
}) => {
  const [hoveredField, setHoveredField] = useState<string | null>(null);
  const [isLoadingReport, setIsLoadingReport] = useState(false);
  const [summaryFields, setSummaryFields] = useState<SummaryField[]>([]);

  const commonFields: SummaryField[] = [
    {
      key: "Vaccines",
      value: vaccines.join(", "),
      field: "Vaccines",
    },
    {
      key: "Sites",
      value: sites.join(", "),
      field: "Sites",
    },
    {
      key: "Data",
      value: data.join(", "),
      field: "Data",
    },
  ];

  const fieldsWithDateRange = [
    {
      key: "From",
      value: FormateDate(dates.fromDate),
      field: "From",
    },
    {
      key: "To",
      value: FormateDate(dates.toDate),
      field: "To",
    },
    ...commonFields,
  ];

  const FieldsWithDate = [
    {
      key: "Date",
      value: FormateDate(dates.toDate),
      field: "Date",
    },
    ...commonFields,
  ];

  useEffect(() => {
    const selectedFields = ["yesterday", "today"].includes(
      dates.dateRangeOption,
    )
      ? FieldsWithDate
      : fieldsWithDateRange;
    setSummaryFields(selectedFields);
  }, [dates]);

  const confirmAndSend = async () => {
    try {
      setIsLoadingReport(true);
      await generateReport();
      handlePageChange(true);
    } catch (error) {
      console.error("Error adding or editing user:", error);
    }
  };

  if (isLoadingReport) {
    return (
      <>
        <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--xl nhsuk-u-margin-bottom-7">
          <h1 className="nhsuk-fieldset__heading">Creating your report</h1>
        </legend>
        <Loading message="Loading..." />
      </>
    );
  }

  return (
    <>
      <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--xl nhsuk-u-margin-bottom-7">
        <h1 className="nhsuk-fieldset__heading">Check and confirm</h1>
      </legend>
      <SummaryList>
        {summaryFields.map(({ key, value, field: fieldName }) => (
          <SummaryList.Row key={key}>
            <SummaryList.Key>{key}</SummaryList.Key>
            <SummaryList.Value>{value}</SummaryList.Value>
            <SummaryList.Actions>
              <button
                className={`anchor-style ${
                  hoveredField === fieldName ? "hovered-color" : "default-color"
                }`}
                onMouseEnter={() => setHoveredField(fieldName)}
                onMouseLeave={() => setHoveredField(null)}
                onClick={() => {
                  if (fieldName === "Vaccines") {
                    setCurrentPage(ReportsPages.ChooseVaccine);
                  } else if (fieldName === "Sites") {
                    setCurrentPage(ReportsPages.ChooseSite);
                  } else if (fieldName === "Data") {
                    setCurrentPage(ReportsPages.ChooseData);
                  } else {
                    setCurrentPage(ReportsPages.ChooseDate);
                  }
                }}
              >
                Change
                <span className="nhsuk-u-visually-hidden">
                  {" "}
                  {key.toLowerCase()}
                </span>
              </button>
            </SummaryList.Actions>
          </SummaryList.Row>
        ))}
      </SummaryList>

      <Button
        className="nhsuk-button"
        data-module="nhsuk-button"
        onClick={confirmAndSend}
      >
        Confirm and create report
      </Button>
    </>
  );
};

export default ReportSummary;
