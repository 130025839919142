import React, { useState, useCallback, useEffect } from "react";
import {
  LeadUserFormValues,
  RegionalUserPages,
} from "../user/pages/RegionalUserPage";
import { BackLink, Button, SummaryList } from "nhsuk-react-components";
import EmailPreview from "../user-management/email-preview/email-preview";
import { NotifyTemplateType } from "../../_shared/shared.enums";
import { User } from "../user/user.models";
import { AccountStatusIds, RoleIds } from "../user/user.enums";
import {
  Organisation,
  OrganisationStatusIds,
} from "./models/organisation.models";
import organisationService from "../../_shared/services/organisation/organisation.service";
import userManagementService from "../../_shared/services/user-management/userManagement.service";
import { toTitleCase } from "../../_shared/shared.functions";
import useOktaService from "../../_shared/services/okta/okta.service";
import { Loading } from "../../_shared/components/Loading";
import { NhsdOrganisation } from "../../_shared/shared.models";

interface CheckAndSendProps {
  leadUserFormValues: LeadUserFormValues;
  selectedOrganisation?: NhsdOrganisation | null;
  regionId: number;
  setCurrentPage: (page: RegionalUserPages) => void;
  setFocusField: React.Dispatch<React.SetStateAction<string>>;
  isExistingOrganisation: boolean;
  setSelectedOrganisation: React.Dispatch<
    React.SetStateAction<NhsdOrganisation | null>
  >;
}

function TextWithLineBreaks(text: string) {
  return (
    <div>
      {text.split("\n").map((line, index) => (
        <span key={index}>
          {line}
          {index < text.split("\n").length - 1 && <br />}
        </span>
      ))}
    </div>
  );
}

const CheckAndSend: React.FC<CheckAndSendProps> = ({
  leadUserFormValues,
  selectedOrganisation,
  regionId,
  setFocusField,
  setCurrentPage,
  isExistingOrganisation,
  setSelectedOrganisation,
}) => {
  const [hoveredField, setHoveredField] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [emailTemplate, setEmailTemplate] = useState<NotifyTemplateType>(null);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  useEffect(() => {
    const fetchEmailTemplate = async (email: string) => {
      try {
        setLoading(true);
        const exists = await useOktaService.checkOktaUserExistence$(email);

        const templateType = exists
          ? NotifyTemplateType.LeadUserWithoutOktaActivation
          : NotifyTemplateType.LeadUserWithOktaActivation;

        setEmailTemplate(templateType);
      } catch (error) {
        console.error("Error checking if user exists:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEmailTemplate(leadUserFormValues.email);
  }, [leadUserFormValues.email]);

  const fields = [
    {
      key: "Organisation name",
      value: toTitleCase(selectedOrganisation.Name),
      field: "organisationName",
    },
    {
      key: "Lead user",
      value: `${toTitleCase(leadUserFormValues.firstName)} ${toTitleCase(leadUserFormValues.lastName)} \n ${leadUserFormValues.email}`,
      field: "firstName",
    },
  ];

  const confirmAndSend = useCallback(async () => {
    setIsSubmitted(true);

    const { firstName, lastName, email } = leadUserFormValues;

    const organisation: Organisation = {
      Name: toTitleCase(selectedOrganisation.Name),
      OrganisationId: selectedOrganisation.Id,
      Code: selectedOrganisation.OrgId,
      Type: selectedOrganisation.PrimaryRoleDescription,
      RegionId: regionId,
      OrganisationStatusId: OrganisationStatusIds.Invited,
    };

    const user: User = {
      UserId: null,
      FirstName: firstName,
      LastName: lastName,
      Email: email,
      Roles: [RoleIds.LeadAdministrator],
      OrganisationId: selectedOrganisation.Id,
      AccountStatusId: AccountStatusIds.Enabled,
      RegionId: regionId,
      IsRegionLead: false,
      Organisation: toTitleCase(selectedOrganisation.Name),
    };

    try {
      if (!isExistingOrganisation) {
        const res = await organisationService.addOrganisation$(organisation);
        setSelectedOrganisation({
          Name: res.Name,
          OrgId: res.Code,
          Id: res.OrganisationId,
        });
        user.OrganisationId = res.OrganisationId;
        await userManagementService.addOrEdit$(user, "Add", true);
        setCurrentPage(RegionalUserPages.OrganiatonOverview);
      } else {
        await userManagementService.addOrEdit$(user, "Add", true);
        setCurrentPage(RegionalUserPages.OrganiatonOverview);
      }
    } catch (error) {
      console.error(error);
    }
  }, [leadUserFormValues, selectedOrganisation, regionId, setCurrentPage]);

  const handleChange = (fieldName: string) => {
    if (fieldName === "organisationName") {
      setCurrentPage(RegionalUserPages.FindAnOrganisation);
    } else {
      setCurrentPage(RegionalUserPages.AddLeadUser);
      setFocusField(fieldName);
    }
  };

  return (
    <>
      <BackLink
        asElement="button"
        onClick={() => setCurrentPage(RegionalUserPages.AddLeadUser)}
      >
        Back
      </BackLink>
      <div className="nhsuk-grid-row">
        <div className="nhsuk-width-container">
          <h1 className="nhsuk-heading-l">Check and send</h1>
          <div className="nhsuk-grid-row nhsuk-grid-column-two-thirds">
            {!isExistingOrganisation && (
              <SummaryList>
                {fields.map(({ key, value, field: fieldName }) => (
                  <SummaryList.Row key={key}>
                    <SummaryList.Key>{key}</SummaryList.Key>
                    <SummaryList.Value>
                      {TextWithLineBreaks(value)}
                    </SummaryList.Value>
                    <SummaryList.Actions>
                      <button
                        className={`anchor-style ${hoveredField === fieldName ? "hovered-color" : "default-color"}`}
                        onMouseEnter={() => setHoveredField(fieldName)}
                        onMouseLeave={() => setHoveredField(null)}
                        onClick={() => {
                          handleChange(fieldName);
                        }}
                      >
                        Change
                        <span className="nhsuk-u-visually-hidden">
                          {" "}
                          {key.toLowerCase()}
                        </span>
                      </button>
                    </SummaryList.Actions>
                  </SummaryList.Row>
                ))}
              </SummaryList>
            )}
            <div className="emailSentContainer">
              <p>This email will be sent to {leadUserFormValues.email}</p>
            </div>

            {loading && <Loading message={"Loading email preview"} />}

            {!loading && (
              <EmailPreview
                personalisation={{
                  FirstName: toTitleCase(leadUserFormValues.firstName),
                }}
                templateType={emailTemplate}
                email={leadUserFormValues.email}
              />
            )}

            <Button
              className="nhsuk-button"
              data-module="nhsuk-button"
              disabled={isSubmitted}
              onClick={confirmAndSend}
            >
              Confirm and send
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckAndSend;
