import React, { useEffect, useState } from "react";
import { Loading } from "../../../_shared/components/Loading";
import { BackLink, Table } from "nhsuk-react-components";
import { Link, useNavigate } from "react-router-dom";
import { User } from "../user.models";
import userDbService from "../userDb.service";
import { DateToLongFormat } from "../../../_shared/shared.functions";
import AccessControl from "../../../_shared/components/accessControl/AccessControl";
import { RoleIds } from "../user.enums";
import usePageSettings from "../../../_shared/hooks/usePageSettings";

export default function DeactivatedUserList() {
  usePageSettings(["service", "manage-users", "deactivated-users"]);
  const navigate = useNavigate();
  const [users, setUsers] = useState<User[]>(null);

  useEffect(() => {
    userDbService.deactivatedUsers$().then((res) => {
      setUsers(res);
    });
  }, []);

  return (
    <AccessControl requiredRoles={[RoleIds.LeadAdministrator]}>
      <BackLink asElement="button" onClick={() => navigate("/manage-users")}>
        Back to Manage users
      </BackLink>

      <h1>Deactivated users</h1>
      {users ? (
        <Table role="table">
          <Table.Head role="rowgroup">
            <Table.Row role="row">
              <Table.Cell role="columnheader">Name</Table.Cell>
              <Table.Cell role="columnheader">Email address</Table.Cell>
              <Table.Cell role="columnheader">Date</Table.Cell>
              <Table.Cell role="columnheader">
                <span className="nhsuk-u-visually-hidden">reactivate user</span>
              </Table.Cell>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {users.map((u: User) => (
              <Table.Row>
                <Table.Cell>
                  {u.FirstName} {u.LastName} {u.Clinical ? "(Clinician)" : ""}
                </Table.Cell>
                <Table.Cell>{u.Email}</Table.Cell>
                <Table.Cell>{DateToLongFormat(u.AuditDateTime)}</Table.Cell>
                <Table.Cell>
                  <Link to={`/manage-users/activate/${u.UserId}`}>
                    Reactivate
                  </Link>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      ) : (
        <Loading />
      )}
    </AccessControl>
  );
}
