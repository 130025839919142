import React, { useEffect } from "react";
import "./NhsComponents.scss";

export default function NhsSelect({
  formFields,
  autoSelectSingleOption = false,
  optionValue = null,
  optionName = null,
  options = [],
  formik,
  ...props
}) {
  const { name, id } = props;
  const label = formFields[name]?.Label;
  const hint = formFields[name]?.Hint;
  const fieldValue = formik.values[name];

  const isSingleOptionSelect = autoSelectSingleOption && options.length === 1;

  useEffect(() => {
    if (isSingleOptionSelect) {
      const selectedValue = optionValue
        ? options[0][optionValue]
        : options[0].Id.toString();
      if (fieldValue !== selectedValue) {
        formik.setFieldValue(name, selectedValue);
      }
    }
  }, [autoSelectSingleOption, options, optionValue, fieldValue, name]);

  const renderError = () =>
    formik.touched[name] &&
    formik.errors[name] && (
      <span className="nhsuk-error-message" id={`${name}Error`}>
        <span className="nhsuk-u-visually-hidden">Error:</span>{" "}
        {formik.errors[name]}
      </span>
    );

  const getSelectValue = () => {
    if (autoSelectSingleOption && options.length === 1) {
      return optionValue ? options[0][optionValue] : options[0].Id;
    }
    return fieldValue;
  };

  return (
    <div
      className={`nhsuk-form-group ${formik.touched[props.name] && formik.errors[props.name] ? "nhsuk-form-group--error" : ""}`}
    >
      {label && (
        <label htmlFor={id || name} className="nhsuk-label">
          {label}
        </label>
      )}
      {hint && (
        <div className="nhsuk-hint nhsuk-radios__hint ps-0" id={`${name}Hint`}>
          {hint}
        </div>
      )}
      {renderError()}
      <select
        id={id || name}
        {...props}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={getSelectValue()}
        className={`nhsuk-select ${isSingleOptionSelect ? "single-option" : ""}`}
      >
        <option key="-1" value=""></option>
        {options.map((o, i) => (
          <option key={i} value={optionValue ? o[optionValue] : o.Id}>
            {optionName ? o[optionName] : o.Name}
          </option>
        ))}
      </select>
    </div>
  );
}
