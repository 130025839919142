import React, { FC, useEffect } from "react";
import { RoleIds } from "../user.enums";
import AccessControl from "../../../_shared/components/accessControl/AccessControl";
import { BackLink, Button, InsetText } from "nhsuk-react-components";
import { ReportDateSelection } from "../../reports/ReportDateSelection";
import NhsCheckboxes from "../../../_shared/components/form/NhsCheckboxes";
import ReportSummary from "../../reports/ReportSummary";
import reportsService, {
  SiteName,
  VaccineProgramName,
} from "../../../_shared/services/reports/reports.service";
import { useUser } from "../UserProvider";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../../_shared/components/Loading";
import useAnalytics from "../../analytics/hooks/useAnalytics";

const fetchSites = async (organisationId: number): Promise<SiteName[]> => {
  try {
    return await reportsService.getAllSites$(organisationId);
  } catch (error) {
    console.error("Failed to fetch sites");
    return [];
  }
};

const fetchVaccinePrograms = async (): Promise<VaccineProgramName[]> => {
  try {
    return await reportsService.getAllVaccinationPrograms$();
  } catch (error) {
    console.error("Failed to fetch vaccine programs");
    return [];
  }
};

export enum ReportsPages {
  CreateReport = "Report",
  ChooseDate = "Report dates",
  ChooseVaccine = "Report vaccines",
  ChooseSite = "Report sites",
  ChooseData = "Report data",
  ReportSummary = "Report check and confirm",
  ReportDownload = "Report ready",
}

export interface SelectedDates {
  dateRangeOption: string;
  fromDate: Date;
  toDate: Date;
}

const getAnalyticsSegment = (currentPage: ReportsPages): string | undefined => {
  switch (currentPage) {
    case ReportsPages.CreateReport:
      return undefined;
    case ReportsPages.ChooseDate:
      return "dates";
    case ReportsPages.ChooseVaccine:
      return "vaccines";
    case ReportsPages.ChooseSite:
      return "sites";
    case ReportsPages.ChooseData:
      return "data";
    case ReportsPages.ReportSummary:
      return "check";
    case ReportsPages.ReportDownload:
      return "ready";
  }
};

type DataOption = {
  value: string;
  label: string;
  hint: string;
};

const dataOptions: DataOption[] = [
  {
    value: "patient",
    label: "Patients",
    hint: "NHS number, name, date of birth, gender, address",
  },
  {
    value: "staff",
    label: "Staff",
    hint: "Recorder, vaccinator, assessing and consenting clinician names and email address",
  },
  {
    value: "site",
    label: "Site or delivery team",
    hint: "Names and ODS codes",
  },
  {
    value: "assessment",
    label: "Assessment and consent",
    hint: "Vaccinated and not givens, date, consent and eligibility details and comments",
  },
  {
    value: "vaccination",
    label: "Vaccination",
    hint: "Date, where the vaccination took place, vaccine details and dose given, site of body, legal mechanism and comments",
  },
];

export const ReportsHome: FC = () => {
  const [currentPage, setCurrentPage] = React.useState<ReportsPages>(
    ReportsPages.CreateReport,
  );
  const currentUser = useUser();
  const navigate = useNavigate();
  const [selectedDates, setSelectedDates] =
    React.useState<SelectedDates | null>(null);
  const [selectedVaccines, setSelectedVaccines] = React.useState<
    string[] | null
  >(null);
  const [selectedSites, setSelectedSites] = React.useState<string[] | null>(
    null,
  );
  // By default, we start with all options selected
  const initialSelectedData = dataOptions.map((option) => option.label);
  const [selectedData, setSelectedData] = React.useState(initialSelectedData);

  const [vaccinationOptions, setVaccinationOptions] = React.useState<
    VaccineProgramName[]
  >([]);
  const [sitesOptions, setSitesOptions] = React.useState<SiteName[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isDisabled, setDisabled] = React.useState<boolean>(false);
  const [reportUrl, setReportUrl] = React.useState<string | null>(null);

  useAnalytics(["service", "reports", getAnalyticsSegment(currentPage)]);

  const buildDateString = (date: Date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // getMonth() returns 0-based index, so we add 1
    const day = date.getDate();

    return `${year}-${month}-${day}`;
  };
  const generateReport = async () => {
    try {
      const reportBlobUrl = await reportsService.generateReport({
        siteIds: sitesOptions
          .filter((site) => selectedSites?.includes(site.Name))
          .map((site) => site.SiteId),
        vaccinationPrograms: vaccinationOptions
          .filter((vaccine) => selectedVaccines?.includes(vaccine.Name))
          .map((vaccine) => vaccine.VaccineProgramId),
        excludeData: dataOptions
          .filter((option) => !selectedData.includes(option.label))
          .map((option) => option.value),
        startDate: buildDateString(selectedDates!.fromDate),
        endDate: buildDateString(selectedDates!.toDate),
      });
      setReportUrl(reportBlobUrl);
    } catch (error) {
      console.error("Error downloading report", error);
    }
  };

  useEffect(() => {
    if (currentUser && currentUser.OrganisationId) {
      setIsLoading(true);
      Promise.all([
        fetchSites(currentUser.OrganisationId),
        fetchVaccinePrograms(),
      ])
        .then(([sites, vaccinePrograms]) => {
          setSitesOptions(sites);
          setVaccinationOptions(vaccinePrograms);
          setIsLoading(false);
          setDisabled(sites.length <= 0 || vaccinePrograms.length <= 0);
        })
        .catch(() => {
          navigate("/error");
        });
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentPage === ReportsPages.CreateReport) {
      setSelectedDates(null);
      setSelectedVaccines(null);
      setSelectedSites(null);
      setSelectedData(initialSelectedData);
    }
    let titleEnd = "RAVS";
    document.title = currentPage + " - " + titleEnd;
  }, [currentPage]);

  const handlePageChange = (isForward: boolean) => {
    switch (currentPage) {
      case ReportsPages.ChooseDate:
        if (isForward) {
          setCurrentPage(ReportsPages.ChooseVaccine);
        } else {
          setCurrentPage(ReportsPages.CreateReport);
        }
        break;
      case ReportsPages.ChooseVaccine:
        if (isForward) {
          setCurrentPage(ReportsPages.ChooseSite);
        } else {
          setCurrentPage(ReportsPages.ChooseDate);
        }
        break;
      case ReportsPages.ChooseSite:
        if (isForward) {
          setCurrentPage(ReportsPages.ChooseData);
        } else {
          setCurrentPage(ReportsPages.ChooseVaccine);
        }
        break;
      case ReportsPages.ChooseData:
        if (isForward) {
          setCurrentPage(ReportsPages.ReportSummary);
        } else {
          setCurrentPage(ReportsPages.ChooseSite);
        }
        break;
      case ReportsPages.ReportSummary:
        if (isForward) {
          setCurrentPage(ReportsPages.ReportDownload);
        } else {
          setCurrentPage(ReportsPages.ChooseData);
        }
        break;
      case ReportsPages.ReportDownload:
        setCurrentPage(ReportsPages.ReportSummary);
        break;
      default:
        setCurrentPage(ReportsPages.CreateReport);
        break;
    }
  };

  const downloadReport = () => {
    reportsService.downloadReport(reportUrl!);
    setCurrentPage(ReportsPages.CreateReport);
  };

  return (
    <AccessControl
      requiredRoles={[RoleIds.Administrator, RoleIds.LeadAdministrator]}
    >
      <div className="nhsuk-grid-row">
        <div className="nhsuk-grid-column-two-thirds">
          {currentPage !== ReportsPages.CreateReport &&
            currentPage !== ReportsPages.ReportDownload && (
              <BackLink
                asElement="button"
                onClick={() => {
                  handlePageChange(false);
                }}
              >
                Back
              </BackLink>
            )}
          {currentPage === ReportsPages.CreateReport && (
            <div>
              <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--xl">
                <h1 className="nhsuk-fieldset__heading">Reports</h1>
              </legend>
              <div className="nhsuk-hint mb-3" id="record-a-service-h1-hint">
                <p>Create and download reports</p>
              </div>
              {!isDisabled && (
                <InsetText>
                  <p>
                    Reports may take longer to process if you choose more data
                    or a wider date range.
                  </p>
                </InsetText>
              )}
              {isDisabled && (
                <InsetText>
                  <p>No vaccination data to report on.</p>
                </InsetText>
              )}
              {isLoading && <Loading />}
              {!isLoading && (
                <Button
                  disabled={isDisabled}
                  onClick={() => {
                    setCurrentPage(ReportsPages.ChooseDate);
                  }}
                >
                  Create report
                </Button>
              )}
            </div>
          )}
          {currentPage === ReportsPages.ChooseDate && (
            <div>
              <ReportDateSelection
                handlePageChange={handlePageChange}
                setSelectedDate={setSelectedDates}
                selectedDates={selectedDates}
              />
            </div>
          )}
          {currentPage === ReportsPages.ChooseVaccine && (
            <div>
              <NhsCheckboxes
                title="Choose vaccine"
                checkboxes={vaccinationOptions.map((vaccine) => ({
                  label: vaccine.Name,
                }))}
                selectedCheckboxes={selectedVaccines}
                setSelectedCheckboxes={setSelectedVaccines}
                handlePageChange={handlePageChange}
                errorMessage="Select vaccines"
              />
            </div>
          )}
          {currentPage === ReportsPages.ChooseSite && (
            <div>
              <NhsCheckboxes
                title="Choose site"
                checkboxes={sitesOptions.map((site) => ({ label: site.Name }))}
                selectedCheckboxes={selectedSites}
                setSelectedCheckboxes={setSelectedSites}
                handlePageChange={handlePageChange}
                errorMessage="Select sites"
              />
            </div>
          )}
          {currentPage === ReportsPages.ChooseData && (
            <div>
              <NhsCheckboxes
                title="Choose data"
                checkboxes={dataOptions.map((option) => option)}
                selectedCheckboxes={selectedData}
                setSelectedCheckboxes={setSelectedData}
                handlePageChange={handlePageChange}
                errorMessage="Select data for report"
              />
            </div>
          )}
          {currentPage === ReportsPages.ReportSummary && (
            <div>
              <ReportSummary
                handlePageChange={handlePageChange}
                dates={selectedDates}
                vaccines={selectedVaccines}
                sites={selectedSites}
                data={
                  selectedData.length === dataOptions.length
                    ? ["All data"]
                    : selectedData
                }
                setCurrentPage={setCurrentPage}
                generateReport={generateReport}
              />
            </div>
          )}
          {currentPage === ReportsPages.ReportDownload && (
            <div>
              <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--xl">
                <h1 className="nhsuk-fieldset__heading">Report is ready</h1>
              </legend>
              <Button onClick={downloadReport}>Download report</Button>
            </div>
          )}
        </div>
      </div>
    </AccessControl>
  );
};
