import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Button, Col, NavLink, Row, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import optionService from "../../_shared/option.service";
import patientService from "./patient.service";
import { number, object, string } from "yup";
import NhsInput from "../../_shared/components/form/NhsInput";
import NhsSelect from "../../_shared/components/form/NhsSelect";
import PatientList from "./PatientList";
import {
  Patient,
  PatientSearchResponse,
  PatientSearchType,
} from "./patient.models";
import {
  HandleFocusChange,
  ScrollToBottom,
} from "../../_shared/shared.functions";
import { Option } from "../../_shared/shared.models";
import { ValidPostcode } from "../../_shared/shared.validation";
import {
  PatientSearchAnalyticsPrimaryCategory,
  PatientPageTitles,
  PatientSearchPdsAnayticsPageName,
} from "./patient.enums";
import useAnalytics from "../analytics/hooks/useAnalytics";
import useDocumentTitle from "../../_shared/hooks/useDocumentTitle";

const formFields = {
  FirstName: {
    Label: "First name",
  },
  LastName: {
    Label: "Last name",
  },
  GenderId: {
    Label: "Gender (Optional)",
  },
  DateOfBirth: {
    Label: "Date of birth",
  },
  Postcode: {
    Label: "Full postcode (Optional)",
  },
};

export default function PatientSearchPds() {
  useAnalytics([
    "service",
    PatientSearchAnalyticsPrimaryCategory.PrimaryCategory,
    PatientSearchPdsAnayticsPageName.SubCategory1,
  ]);
  useDocumentTitle(PatientPageTitles.PdsPatientSearch);
  const [loading, setLoading] = useState(false);
  const [searchValues, setSearchValues] = useState(null as Patient);
  const [patient, setPatient] = useState(null as Patient);

  const [genderOptions, setGenderOptions] = useState([] as Option[]);
  const [dateOfBirthError, setDateOfBirthError] = React.useState(false);

  const patientSearchType: PatientSearchType = {
    NhsNumberSearch: false,
    PdsSearch: true,
    RavsSearch: false,
  };

  useEffect(() => {
    const getGenderOptions = async () => {
      const response = await optionService.getCachedOption$("Genders");
      setGenderOptions(response);
    };
    getGenderOptions();
  }, []);

  const formik = useFormik({
    initialValues: {
      FirstName: "",
      LastName: "",
      DateOfBirth_1: "",
      DateOfBirth_2: "",
      DateOfBirth_3: "",
      DateOfBirth: "",
      GenderId: "",
      Gender: "",
      Postcode: "",
    },
    validationSchema: object({
      FirstName: string().required("Enter the first name"),
      LastName: string().required("Enter the last name"),
      GenderId: string(),
      Gender: string(),
      Postcode: string().test(
        "isValidPostcode",
        "Enter the full postcode in the correct format",
        function (value) {
          if (value) return ValidPostcode(value);
          else return true;
        },
      ),
      DateOfBirth_1: number().required("Enter the date of birth"),
    }),
    enableReinitialize: true,
    onSubmit: async (values) => {
      setDateOfBirthError(false);
      setPatient(null);

      let patient = values as unknown as Patient;

      patient.FirstName = patient.FirstName.trim();
      patient.LastName = patient.LastName.trim();

      if (
        values.DateOfBirth_1 ||
        values.DateOfBirth_2 ||
        values.DateOfBirth_3
      ) {
        let day = values.DateOfBirth_1;
        let month = values.DateOfBirth_2;
        let year = values.DateOfBirth_3;

        if (/^(d{1,2})$/.test(day)) {
          setDateOfBirthError(true);
          return;
        }

        if (/^(d{1,2})$/.test(month)) {
          setDateOfBirthError(true);
          return;
        }

        if (/^(d{2,4})$/.test(year)) {
          setDateOfBirthError(true);
          return;
        }

        const today = new Date();
        const allowedYear = today.getFullYear();
        if (!year || Number(year) > allowedYear) {
          setDateOfBirthError(true);
          return;
        }

        const inputDate = new Date(year + "-" + month + "-" + day);
        const currentDate = new Date();
        if (inputDate > currentDate) {
          setDateOfBirthError(true);
          return;
        }

        if (Number(month) < 1 || Number(month) > 12) {
          setDateOfBirthError(true);
          return;
        }

        if (Number(day) < 1 || Number(day) > 31) {
          setDateOfBirthError(true);
          return;
        }

        if (
          (Number(month) === 4 ||
            Number(month) === 6 ||
            Number(month) === 9 ||
            Number(month) === 11) &&
          Number(day) === 31
        ) {
          setDateOfBirthError(true);
          return;
        }

        if (Number(month) === 2) {
          const isLeapYear =
            (Number(year) % 4 === 0 && Number(year) % 100 !== 0) ||
            Number(year) % 400 === 0;

          if (Number(day) > 29 || (Number(day) === 29 && !isLeapYear)) {
            setDateOfBirthError(true);
            return;
          }
        }
      }

      if (
        values.DateOfBirth_1 &&
        values.DateOfBirth_2 &&
        values.DateOfBirth_3
      ) {
        let dateOfBirth_1 =
          values.DateOfBirth_1.toString().length === 1
            ? "0" + values.DateOfBirth_1.toString()
            : values.DateOfBirth_1.toString();
        let dateOfBirth_2 =
          values.DateOfBirth_2.toString().length === 1
            ? "0" + values.DateOfBirth_2.toString()
            : values.DateOfBirth_2.toString();
        patient.DateOfBirth =
          values.DateOfBirth_3.toString() +
          "-" +
          dateOfBirth_2 +
          "-" +
          dateOfBirth_1;
      } else {
        setDateOfBirthError(true);
        return;
      }

      if (values.GenderId)
        patient.Gender = genderOptions.find(
          (g) => Number(g.Id) === Number(values.GenderId),
        )?.Name;

      setSearchValues(patient);
      setPatient(null);
      setLoading(true);
      let response: PatientSearchResponse | null = null;
      try {
        response = await patientService.detailsSearch$(patient);
      } finally {
        await processResponse(response);
        setLoading(false);
        ScrollToBottom();
      }
    },
  });

  const processResponse = async (response: PatientSearchResponse | null) => {
    if (response === null || response.PdsPatient === null) {
      return;
    }

    const pdsPatient = response.PdsPatient;
    const ravsPatientId = response?.RavsPatient?.PatientId;

    if (pdsPatient.TooManyReturnedResults) {
      setPatient(pdsPatient);
      return;
    }

    if (ravsPatientId) {
      setPatient({
        ...pdsPatient,
        PatientId: ravsPatientId,
      });
    } else {
      const res = await patientService.add$(response.PdsPatient);
      if (res !== null) {
        setPatient({
          ...pdsPatient,
          PatientId: res.PatientId,
        });
      }
    }
  };

  const handleWarnings = (e, data) => {
    if (data.DateOfBirth) {
      setDateOfBirthError(false);
      formik.setFieldError("DateOfBirth", null);
    }
  };

  return (
    <fieldset className="nhsuk-fieldset">
      <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--xl">
        <h1 className="nhsuk-fieldset__heading">Find a patient</h1>
      </legend>

      <div className="nhsuk-tabs" data-module="nhsuk-tabs">
        <ul
          className="nhsuk-tabs__list"
          aria-label="Patient search by demographics tab"
        >
          <li className="nhsuk-tabs__list-item">
            <NavLink tag={Link} to="/patient/search/nhs-number">
              By NHS number
            </NavLink>
          </li>
          <li className="nhsuk-tabs__list-item nhsuk-tabs__list-item--selected">
            <NavLink href="#" active>
              By demographics
            </NavLink>
          </li>
          <li className="nhsuk-tabs__list-item">
            <NavLink tag={Link} to="/patient/search/records">
              By local records
            </NavLink>
          </li>
        </ul>

        <div className="nhsuk-tabs__panel pt-0" id="by-nhs-number">
          <br />
          <h2 className="nhsuk-table__caption">Search by demographics</h2>
          <h3 className="nhsuk-label">
            Enter at least the first name, last name and date of birth. Entering
            more fields will increase your chances of finding a match
            immediately.
          </h3>
          <form onSubmit={formik.handleSubmit}>
            <Row>
              <Col md="5">
                <NhsInput
                  name="FirstName"
                  formFields={formFields}
                  formik={formik}
                ></NhsInput>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <NhsInput
                  name="LastName"
                  formFields={formFields}
                  formik={formik}
                ></NhsInput>
              </Col>
            </Row>
            <NhsSelect
              name="GenderId"
              formFields={formFields}
              formik={formik}
              options={genderOptions}
            ></NhsSelect>
            <Row>
              <Col md="4">
                <NhsInput
                  aria-label="Postcode field optional"
                  onInput={(e) =>
                    (e.target.value = ("" + e.target.value).toUpperCase())
                  }
                  name="Postcode"
                  maxLength={8}
                  formFields={formFields}
                  type="Postcode"
                  formik={formik}
                ></NhsInput>
              </Col>
            </Row>
            <>
              <div className="nhsuk-form-group">
                <fieldset
                  className="nhsuk-fieldset"
                  aria-label="DateOfBirth-hint"
                  role="group"
                >
                  <legend className="nhsuk-fieldset__legend">
                    Date of birth
                  </legend>
                  <div className="nhsuk-hint" id="DateOfBirth-hint">
                    For example, 31 03 1980
                  </div>

                  <div className="nhsuk-date-input" id="DateOfBirth">
                    {dateOfBirthError && (
                      <span
                        key="DateOfBirthIdErrorRequiredSpan1"
                        className="nhsuk-error-message"
                        id="DateOfBirthRequiredError1"
                      >
                        <span
                          key="DateOfBirthErrorRequiredSpan1"
                          className="nhsuk-u-visually-hidden"
                        >
                          Error:
                        </span>{" "}
                        Please enter valid date of birth
                      </span>
                    )}

                    {formik.touched.DateOfBirth_1 &&
                      formik.errors.DateOfBirth_1 && (
                        <span
                          key="DateOfBirthIdErrorRequiredSpan2"
                          className="nhsuk-error-message"
                          id="DateOfBirthRequiredError2"
                        >
                          <span
                            key="DateOfBirthErrorRequiredSpan2"
                            className="nhsuk-u-visually-hidden"
                          >
                            Error:
                          </span>{" "}
                          {formik.errors.DateOfBirth_1}
                        </span>
                      )}

                    <div className="nhsuk-date-input__item">
                      <div className="nhsuk-form-group">
                        <input
                          className="nhsuk-input nhsuk-date-input__input "
                          id="DateOfBirthHidden"
                          name="DateOfBirth"
                          type="hidden"
                          value={
                            formik.values.DateOfBirth_3 +
                            "-" +
                            formik.values.DateOfBirth_2 +
                            "-" +
                            formik.values.DateOfBirth_1
                          }
                          onChange={(e) => {
                            formik.setFieldTouched("DateOfBirth");
                            formik.handleChange(e);
                          }}
                          onBlur={(e) => {
                            formik.setFieldTouched("DateOfBirth");
                            formik.handleBlur(e);
                          }}
                        />
                      </div>
                    </div>
                    <br></br>
                    <br></br>
                    <div className="nhsuk-date-input__item">
                      <div className="nhsuk-form-group">
                        <label
                          className="nhsuk-label nhsuk-date-input__label"
                          htmlFor="DateOfBirth_1"
                        >
                          Day
                        </label>

                        <input
                          className="nhsuk-input nhsuk-date-input__input nhsuk-input--width-2"
                          id="DateOfBirth_1"
                          name="DateOfBirth_1"
                          type="number"
                          inputMode="numeric"
                          value={formik.values.DateOfBirth_1}
                          placeholder="DD"
                          maxLength={2}
                          onChange={(e) => {
                            formik.handleChange(e);
                            handleWarnings(e, { DateOfBirth: true });
                            formik.setFieldTouched("DateOfBirth");
                            HandleFocusChange(e);
                          }}
                          onBlur={(e) => {
                            formik.handleBlur(e);
                            handleWarnings(e, { DateOfBirth: true });
                            formik.setFieldTouched("DateOfBirth");
                            HandleFocusChange(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className="nhsuk-date-input__item">
                      <div className="nhsuk-form-group">
                        <label
                          className="nhsuk-label nhsuk-date-input__label"
                          htmlFor="DateOfBirth_2"
                        >
                          Month
                        </label>

                        <input
                          className="nhsuk-input nhsuk-date-input__input nhsuk-input--width-2"
                          id="DateOfBirth_2"
                          name="DateOfBirth_2"
                          type="number"
                          inputMode="numeric"
                          value={formik.values.DateOfBirth_2}
                          placeholder="MM"
                          maxLength={2}
                          onChange={(e) => {
                            formik.handleChange(e);
                            handleWarnings(e, { DateOfBirth: true });
                            formik.setFieldTouched("DateOfBirth");
                            HandleFocusChange(e);
                          }}
                          onBlur={(e) => {
                            formik.handleBlur(e);
                            handleWarnings(e, { DateOfBirth: true });
                            formik.setFieldTouched("DateOfBirth");
                            HandleFocusChange(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className="nhsuk-date-input__item">
                      <div className="nhsuk-form-group">
                        <label
                          className="nhsuk-label nhsuk-date-input__label"
                          htmlFor="DateOfBirth_3"
                        >
                          Year
                        </label>

                        <input
                          className="nhsuk-input nhsuk-date-input__input nhsuk-input--width-4"
                          id="DateOfBirth_3"
                          name="DateOfBirth_3"
                          type="number"
                          inputMode="numeric"
                          value={formik.values.DateOfBirth_3}
                          placeholder="YYYY"
                          maxLength={4}
                          onChange={(e) => {
                            formik.handleChange(e);
                            handleWarnings(e, { DateOfBirth: true });
                            formik.setFieldTouched("DateOfBirth");
                            HandleFocusChange(e);
                          }}
                          onBlur={(e) => {
                            formik.handleBlur(e);
                            handleWarnings(e, { DateOfBirth: true });
                            formik.setFieldTouched("DateOfBirth");
                            HandleFocusChange(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
            </>
            {loading ? (
              <Spinner></Spinner>
            ) : (
              <Button
                type="submit"
                className="nhsuk-button"
                data-module="nhsuk-button"
              >
                Search
              </Button>
            )}
          </form>
        </div>
      </div>

      {!loading ? (
        <PatientList
          patient={patient}
          patientSearchType={patientSearchType}
          searchValues={searchValues}
        ></PatientList>
      ) : (
        ""
      )}
    </fieldset>
  );
}
