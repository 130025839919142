import React from "react";
import userService from "./user.service";
import Login from "./pages/Login";
import { Navigate } from "react-router-dom";
import useRegionalAdministratorCheck from "../../_shared/hooks/useRegionalAdministratorCheck";

export default function UserGuard({ element }) {
  const { shouldNavigateToRegional } = useRegionalAdministratorCheck();

  if (shouldNavigateToRegional) {
    return <Navigate to="/regional" />;
  }

  if (userService.getUser()) return element;

  return <Login></Login>;
}
