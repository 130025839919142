import React from "react";
import "./EnvironmentBanner.css";

export default function EnvironmentBanner() {
  let mode = "";
  const url = window.location.href;
  if (url.includes("training")) {
    mode = "TRAINING";
  } else {
    mode = "BETA";
  }

  const modeClassName =
    mode === "BETA" ? "nhsuk-tag--blue" : "nhsuk-tag--green";
  return (
    <div className="phase-banner-container">
      <div className="nhsuk-width-container phase-banner">
        <p className="phase-banner__content">
          <strong className={`nhsuk-tag ${modeClassName}`}>
            {mode === "BETA" ? "Beta" : "Training"}
          </strong>
          <span className="phase-banner__text">
            {mode === "BETA" ? createBetaMessage() : createTrainingMessage()}
          </span>
        </p>
      </div>
    </div>
  );
}

function createBetaMessage() {
  return (
    <>
      This is a new service - your{" "}
      <a
        className="nhsuk-link"
        href="https://feedback.digital.nhs.uk/jfe/form/SV_ezgoupJNznAkT6m"
        target="_blank"
      >
        feedback (opens in a new tab)
      </a>{" "}
      will help us to improve it.
    </>
  );
}

function createTrainingMessage() {
  return <>This is a training version of the service.</>;
}
