import { useLocation } from "react-router-dom";
import { PageType } from "../../../_shared/shared.enums";
import { useMemo } from "react";
import { GetPageDetails } from "../../../_shared/shared.functions";

export default function usePageDetails() {
  const location = useLocation();
  const pageDetails = useMemo(
    () => GetPageDetails(location.pathname),
    [location.pathname],
  );

  return {
    id: pageDetails.Id,
    parentId: pageDetails.ParentId,
    pageType: pageDetails.PageType,
    capitalisedPageType:
      pageDetails.PageType?.charAt(0).toUpperCase() +
      pageDetails.PageType?.slice(1),
    isAddPage: pageDetails.PageType === PageType.Add,
  };
}
