import React, { useState } from "react";
import { Formik, Field, Form, FieldArray } from "formik";
import * as Yup from "yup";
import { ErrorSummary } from "nhsuk-react-components";

interface Checkbox {
  label: string;
  hint?: string;
}

interface CheckboxGroupProps {
  title: string;
  checkboxes: Checkbox[];
  selectedCheckboxes: string[];
  setSelectedCheckboxes: React.Dispatch<React.SetStateAction<string[]>>;
  handlePageChange: (isForward: boolean) => void;
  errorMessage: string;
}

interface FormValues {
  items: { [key: string]: boolean };
}

const CheckboxGroup: React.FC<CheckboxGroupProps> = ({
  title,
  checkboxes,
  selectedCheckboxes,
  setSelectedCheckboxes,
  handlePageChange,
  errorMessage,
}) => {
  const [showErrorSummary, setShowErrorSummary] = useState(false);
  const [hoveredField, setHoveredField] = useState<string | null>(null);

  const initialValues: FormValues = {
    items: checkboxes.reduce(
      (acc, item) => {
        if (selectedCheckboxes !== null) {
          acc[item.label] = selectedCheckboxes.includes(item.label);
        } else {
          acc[item.label] = false;
        }
        return acc;
      },
      {} as { [key: string]: boolean },
    ),
  };

  const validationSchema = Yup.object({
    items: Yup.object().test("at-least-one-checked", errorMessage, (value) => {
      return Object.values(value).some((v) => v);
    }),
  });

  const scrollToField = (fieldName: string) => {
    const element = document.getElementById(fieldName);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      element.focus();
    }
  };

  const handleSubmit = (values: FormValues) => {
    const selectedCheckboxes = Object.keys(values.items).filter(
      (key) => values.items[key],
    );
    setSelectedCheckboxes(
      selectedCheckboxes.length > 0 ? selectedCheckboxes : [],
    );
    handlePageChange(true);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ errors }) => (
        <Form>
          {showErrorSummary && Object.keys(errors).length > 0 && (
            <ErrorSummary id="formErrors">
              <ErrorSummary.Title>There is a problem</ErrorSummary.Title>
              <ErrorSummary.Body>
                <ErrorSummary.List>
                  {Object.keys(errors).map((fieldName, index) => (
                    <ErrorSummary.Item key={index}>
                      <button
                        type="button"
                        className={`anchor-style ${
                          hoveredField === fieldName
                            ? "hovered-color"
                            : "error-color"
                        }`}
                        onMouseEnter={() => setHoveredField(fieldName)}
                        onMouseLeave={() => setHoveredField(null)}
                        key={index}
                        onClick={() => scrollToField(fieldName)}
                      >
                        {errors[fieldName]}
                      </button>
                    </ErrorSummary.Item>
                  ))}
                </ErrorSummary.List>
              </ErrorSummary.Body>
            </ErrorSummary>
          )}
          <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--xl nhsuk-u-margin-bottom-7">
            <h1 className="nhsuk-fieldset__heading">{title}</h1>
          </legend>
          {errors.items && typeof errors.items === "string" && (
            <div className="nhsuk-error-message">{errors.items}</div>
          )}
          <FieldArray name="items">
            {() => (
              <div className="nhsuk-checkboxes">
                {checkboxes.map((checkbox, i) => (
                  <div key={i} className="nhsuk-checkboxes__item">
                    <Field
                      className="nhsuk-checkboxes__input"
                      type="checkbox"
                      name={`items.${checkbox.label}`}
                      id={`items.${checkbox.label}`}
                      aria-describedby={
                        checkbox.hint && `items.${checkbox.label}-hint`
                      }
                    />
                    <label
                      className="nhsuk-label nhsuk-checkboxes__label"
                      htmlFor={`items.${checkbox.label}`}
                    >
                      {checkbox.label}
                    </label>
                    {checkbox.hint && (
                      <div
                        className="nhsuk-hint nhsuk-checkboxes__hint"
                        id={`items.${checkbox.label}-hint`}
                      >
                        {checkbox.hint}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </FieldArray>
          <button
            type="submit"
            onClick={() => setShowErrorSummary(true)}
            className="nhsuk-button nhsuk-u-margin-top-5"
          >
            Continue
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default CheckboxGroup;
