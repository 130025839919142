import React from "react";
import { Link } from "react-router-dom";
import { NavLink } from "reactstrap";
import "./layout.scss";
import { useUser } from "../user/UserProvider";
import { RoleIds, UserPaths } from "../user/user.enums";
import { SignOut } from "../user/user.functions";
import AccessControl from "../../_shared/components/accessControl/AccessControl";
import { SiteVaccinesPaths } from "../site-vaccines/site-vaccines.enums";
import { SiteVaccines } from "../site-vaccines/site-vaccines.models";

export default function Header() {
  const user = useUser();

  return (
    <header className="nhsuk-header" role="banner">
      <div className="nhsuk-header__container">
        <div className="nhsuk-header__logo">
          <NavLink
            className="nhsuk-header__link nhsuk-header__link--service"
            tag={Link}
            to="/home"
          >
            <svg
              className="nhsuk-logo"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 40 16"
              height="40"
              width="100"
              aria-labelledby="nhs-logo-title"
            >
              <title id="nhs-logo-title">NHS Logo</title>
              <path
                className="nhsuk-logo__background"
                fill="#005eb8"
                d="M0 0h40v16H0z"
              ></path>
              <path
                className="nhsuk-logo__text"
                fill="#fff"
                d="M3.9 1.5h4.4l2.6 9h.1l1.8-9h3.3l-2.8 13H9l-2.7-9h-.1l-1.8 9H1.1M17.3 1.5h3.6l-1 4.9h4L25 1.5h3.5l-2.7 13h-3.5l1.1-5.6h-4.1l-1.2 5.6h-3.4M37.7 4.4c-.7-.3-1.6-.6-2.9-.6-1.4 0-2.5.2-2.5 1.3 0 1.8 5.1 1.2 5.1 5.1 0 3.6-3.3 4.5-6.4 4.5-1.3 0-2.9-.3-4-.7l.8-2.7c.7.4 2.1.7 3.2.7s2.8-.2 2.8-1.5c0-2.1-5.1-1.3-5.1-5 0-3.4 2.9-4.4 5.8-4.4 1.6 0 3.1.2 4 .6"
              ></path>
            </svg>
            <span className="nhsuk-header__service-name">
              Record a vaccination
            </span>
          </NavLink>
        </div>
      </div>

      <div className="nhsuk-navigation-container">
        {user && (
          <nav
            className="nhsuk-navigation"
            id="header-navigation"
            role="navigation"
            aria-label="Primary navigation"
          >
            <ul className="nhsuk-header__navigation-list">
              <AccessControl
                showUnauthorisedMessage={false}
                requiredRoles={[
                  RoleIds.LeadAdministrator,
                  RoleIds.Administrator,
                  RoleIds.Recorder,
                ]}
              >
                <li className={`nhsuk-header__navigation-item`}>
                  <NavLink
                    className="nhsuk-header__navigation-link"
                    tag={Link}
                    to="/patient/search/nhs-number"
                  >
                    Find a patient
                  </NavLink>
                </li>
              </AccessControl>
              <AccessControl
                showUnauthorisedMessage={false}
                requiredRoles={[
                  RoleIds.LeadAdministrator,
                  RoleIds.Administrator,
                ]}
              >
                <li className={`nhsuk-header__navigation-item`}>
                  <NavLink
                    className="nhsuk-header__navigation-link"
                    tag={Link}
                    to={SiteVaccinesPaths.SiteVaccinesList}
                    state={[null, [] as SiteVaccines[]]}
                  >
                    Vaccines
                  </NavLink>
                </li>
              </AccessControl>
              <AccessControl
                showUnauthorisedMessage={false}
                requiredRoles={[
                  RoleIds.LeadAdministrator,
                  RoleIds.Administrator,
                ]}
              >
                <li className={`nhsuk-header__navigation-item`}>
                  <a className="nhsuk-header__navigation-link" href="/reports">
                    Reports
                  </a>
                </li>
              </AccessControl>
              <AccessControl
                showUnauthorisedMessage={false}
                requiredRoles={[RoleIds.LeadAdministrator]}
              >
                <li className={`nhsuk-header__navigation-item`}>
                  <NavLink
                    className="nhsuk-header__navigation-link"
                    tag={Link}
                    to="/manage-users"
                  >
                    Manage users
                  </NavLink>
                </li>
              </AccessControl>

              <li className="nhsuk-header__navigation-item nhsuk-header__navigation-item--home">
                <NavLink
                  className="nhsuk-header__navigation-link"
                  tag={Link}
                  to="/home"
                >
                  Home
                </NavLink>
              </li>
              <li className="cutoff-nav nhsuk-header__navigation-item">
                <NavLink
                  className="nhsuk-header__navigation-link"
                  tag={Link}
                  to={UserPaths.Profile}
                >
                  {user?.Email}
                </NavLink>
              </li>
              <li className="nhsuk-header__navigation-item">
                <NavLink
                  className="nhsuk-header__navigation-link"
                  tag={Link}
                  onClick={SignOut}
                >
                  Log Out
                </NavLink>
              </li>
              <li className="nhsuk-mobile-menu-container">
                <button
                  className="nhsuk-header__menu-toggle nhsuk-header__navigation-link"
                  id="toggle-menu"
                  aria-expanded="false"
                >
                  <span className="nhsuk-u-visually-hidden">Browse</span>
                  More
                  <svg
                    className="nhsuk-icon nhsuk-icon__chevron-down"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    focusable="false"
                  >
                    <path d="M15.5 12a1 1 0 0 1-.29.71l-5 5a1 1 0 0 1-1.42-1.42l4.3-4.29-4.3-4.29a1 1 0 0 1 1.42-1.42l5 5a1 1 0 0 1 .29.71z"></path>
                  </svg>
                </button>
              </li>
            </ul>
          </nav>
        )}
      </div>
    </header>
  );
}
