import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { GetCookie, SetCookie } from "./cookie.functions";
import { RadioValues } from "../../../_shared/shared.enums";
import { Cookie } from "./cookie.enums";

const CookieContext = createContext(null);

export function useCookie() {
  return useContext(CookieContext);
}

export default function CookieProvider({ children }) {
  const [cookieConsent, setCookieConsent] = useState(GetCookie(Cookie.Name));

  const url = useMemo(() => {
    const host = window.location.hostname;

    if (host === "www.ravs.england.nhs.uk") {
      return "https://assets.adobedtm.com/f8560165ec6a/5d4f99bbc369/launch-6e00e0b450d7.min.js";
    }
    if (host === "localhost" || host === "www.ravs-dev.england.nhs.uk") {
      return "https://assets.adobedtm.com/f8560165ec6a/5d4f99bbc369/launch-be15b0a7e1ff-development.min.js";
    }
    if (
      host === "www.ravs-qa.england.nhs.uk" ||
      host === "www.ravs-demo.england.nhs.uk" ||
      host === "www.ravs-training.england.nhs.uk"
    ) {
      return "//assets.adobedtm.com/launch-ENa55251f775954d9592d110a6b4ad26a1-staging.min.js";
    } else return "";
  }, []);

  useEffect(() => {
    if (url && cookieConsent === RadioValues.true) {
      const script = document.createElement("script");
      script.src = url;
      script.async = true;
      document.head.appendChild(script);
    }
  }, []);

  const updateCookie = (value) => {
    SetCookie(Cookie.Name, value, 365);
    setCookieConsent(value);
  };

  return (
    <CookieContext.Provider value={{ cookieConsent, updateCookie }}>
      {children}
    </CookieContext.Provider>
  );
}
