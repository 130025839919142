import React from "react";

export default function NhsOptionSummary({
  name,
  formFields,
  formik,
  label = null,
  optionValue = null,
  optionName = null,
  options,
  showChange = null,
  onChange = null,
}) {
  const optId = optionValue ?? "Id";
  const optName = optionName ?? "Name";
  const value =
    options && options.find((o) => o[optId] == formik?.values[name])?.[optName];
  return (
    <>
      {value && (
        <div className="nhsuk-summary-list__row">
          <dt className="nhsuk-summary-list__key">
            {label ?? formFields[name]?.Label}
          </dt>

          <dd className="nhsuk-summary-list__value">{value}</dd>

          <dd className="nhsuk-summary-list__actions">
            {showChange && onChange && (
              <button className="nhs-link-button" onClick={onChange}>
                Change
              </button>
            )}
          </dd>
        </div>
      )}
    </>
  );
}
