import * as React from "react";

export default function NhsAutocompleteInput({
  label = null,
  hint = null,
  formik,
  customError = null,
  handleCustomChange,
  noMatchingRecord,
  selectFromDropDown,
  ...props
}) {
  return (
    <div>
      <label htmlFor={props.id || props.name} className="nhsuk-label">
        {label}
      </label>

      {hint && (
        <div className="nhsuk-hint" id={`${props.name}-hint`}>
          {hint}
        </div>
      )}

      {formik.touched[props.name] && formik.errors[props.name] ? (
        <span className="nhsuk-error-message" id={`${props.name}Error`}>
          <span className="nhsuk-u-visually-hidden">Error:</span>{" "}
          {formik.errors[props.name]}
        </span>
      ) : null}

      {noMatchingRecord ? (
        <span className="nhsuk-error-message" id={`${props.name}Error`}>
          <span className="nhsuk-u-visually-hidden">Error:</span> No matching
          record found with {formik.values[props.name]}
        </span>
      ) : null}

      {formik.errors[props.name] === undefined && customError ? (
        <span className="nhsuk-error-message" id={`${props.name}Error`}>
          <span className="nhsuk-u-visually-hidden">Error:</span> {customError}
        </span>
      ) : null}

      {selectFromDropDown ? (
        <span className="nhsuk-error-message" id={`${props.name}Error`}>
          <span className="nhsuk-u-visually-hidden">Error:</span> Select from
          searched result
        </span>
      ) : null}

      <input
        aria-labelledby={props.name}
        id={props.name}
        {...props}
        type="text"
        value={formik.values[props.name]}
        onChange={(e) => {
          handleCustomChange(e);
          formik.handleChange(e);
        }}
        onBlur={(e) => {
          handleCustomChange(e);
          formik.handleChange(e);
        }}
        className={
          "nhsuk-input " +
          ((formik.touched[props.name] && formik.errors[props.name]) ||
          noMatchingRecord ||
          selectFromDropDown
            ? "nhsuk-input--error"
            : "")
        }
        placeholder={props.placeholder}
      />
    </div>
  );
}
