import React from "react";

export default function NhsYesNo({
  formFields,
  formik,
  lableYes = "Yes",
  lableNo = "No",
  ...props
}) {
  const label = formFields[props.name]?.Label;
  const hint = formFields[props.name]?.Hint;
  return (
    <div
      className={`nhsuk-form-group ${formik.touched[props.name] && formik.errors[props.name] ? "nhsuk-form-group--error" : ""}`}
    >
      {label && (
        <label htmlFor={props.id || props.name} className="nhsuk-label">
          {label}
        </label>
      )}

      {hint && (
        <div
          className="nhsuk-hint nhsuk-radios__hint ps-0"
          id={`${props.name}Hint`}
        >
          {hint}
        </div>
      )}

      {formik.touched[props.name] && formik.errors[props.name] ? (
        <span className="nhsuk-error-message" id={`${props.name}Error`}>
          <span className="nhsuk-u-visually-hidden">Error:</span>{" "}
          {formik.errors[props.name]}
        </span>
      ) : null}

      <div className="nhsuk-radios">
        <div className="nhsuk-radios__item">
          <input
            type="radio"
            id={`${props.name}Yes`}
            {...props}
            onChange={formik.handleChange}
            checked={formik.values[props.name] === "true"}
            value="true"
            className="nhsuk-radios__input"
            aria-describedby={`${props.name}Yes`}
          />

          <label
            htmlFor={`${props.name}Yes`}
            className="nhsuk-label nhsuk-radios__label"
          >
            {lableYes}
          </label>
        </div>

        <div className="nhsuk-radios__item">
          <input
            type="radio"
            id={`${props.name}No`}
            {...props}
            onChange={formik.handleChange}
            checked={formik.values[props.name] === "false"}
            value="false"
            className="nhsuk-radios__input"
            aria-describedby={`${props.name}No`}
          />

          <label
            htmlFor={`${props.name}No`}
            className="nhsuk-label nhsuk-radios__label"
          >
            {lableNo}
          </label>
        </div>
      </div>
    </div>
  );
}
