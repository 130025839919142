import { Field, FieldProps } from "formik";
import React from "react";
import { DateInput } from "nhsuk-react-components";

interface DateInputProps extends FieldProps {
  /**
   * The label for the date input form group.
   */
  label: string;
}

/**
 * Renders a formik-aware date input.
 * The formik field must be an object with day, month, and year properties.
 *
 * usage:
 * ```tsx
 * <Field name="date" label="Start Date" component={FormikDateInput} />
 * ```
 */
const FormikDateInput: React.FC<DateInputProps> = ({ field, form, label }) => {
  const meta = form.getFieldMeta(field.name);
  let globalError = false;
  let errorMessage = "";
  if (typeof meta.error === "object") {
    // If there are multiple errors, just show the first one
    errorMessage = (Object.values(meta.error) as string[])[0];
  } else {
    errorMessage = typeof meta.error === "string" ? meta.error : "";
    globalError = true;
  }

  return (
    <DateInput
      id={field.name}
      label={label}
      hint="For example, 15 3 2024"
      error={errorMessage}
      {...field}
    >
      <Field name={`${field.name}.day`}>
        {({ field, meta }) => (
          <DateInput.Day
            error={globalError || meta.error ? meta.error : ""}
            id={field.name}
            {...field}
            pattern={null}
          />
        )}
      </Field>
      <Field name={`${field.name}.month`}>
        {({ field, meta }) => (
          <DateInput.Month
            error={globalError || meta.error ? meta.error : ""}
            id={field.name}
            {...field}
            pattern={null}
          />
        )}
      </Field>
      <Field name={`${field.name}.year`}>
        {({ field, meta }) => (
          <DateInput.Year
            error={globalError || meta.error ? meta.error : ""}
            id={field.name}
            {...field}
            pattern={null}
          />
        )}
      </Field>
    </DateInput>
  );
};

/**
 * Renders a formik-aware date range input.
 * The formik field must be an object with a fromDate and toDate property,
 * which are themselves objects with day, month, and year properties.
 *
 * usage:
 * ```tsx
 * <Field name="customDate" component={FormikDateRangeInput} />
 * ```
 */
const FormikDateRangeInput: React.FC<FieldProps> = ({ field, form }) => {
  const meta = form.getFieldMeta(field.name);
  console.log("DATE RANGE ERROR", meta.error);

  // If the error is a string, display it. Otherwise, do nothing and let the sub-components handle it.
  const error = typeof meta.error === "string" ? meta.error : "";

  const errorClass = error ? "nhsuk-form-group--error" : "";
  return (
    <div className={`nhsuk-form-group ${errorClass}`} id={field.name}>
      {error && <p className="nhsuk-error-message">{error}</p>}

      <Field
        name={`${field.name}.fromDate`}
        label="From"
        component={FormikDateInput}
      />
      <Field
        name={`${field.name}.toDate`}
        label="To"
        component={FormikDateInput}
      />
    </div>
  );
};

export default FormikDateRangeInput;
