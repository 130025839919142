import React, { useEffect, useState } from "react";
import userService from "../user.service";
import { Redirect } from "../../../_shared/shared.functions";
import { Navigate, useLocation } from "react-router-dom";
import {
  LoginAnalyticsPageNames,
  RoleIds,
  UserPageTitles,
  UserPaths,
} from "../user.enums";
import { Loading } from "../../../_shared/components/Loading";
import useAnalytics from "../../analytics/hooks/useAnalytics";
import useDocumentTitle from "../../../_shared/hooks/useDocumentTitle";
import { Button } from "nhsuk-react-components";

export default function Login() {
  useAnalytics(["service", LoginAnalyticsPageNames.PrimaryCategory]);
  useDocumentTitle(UserPageTitles.Login);
  const [user, setUser] = useState(userService.getUser());
  const [loading, setLoading] = useState(true);

  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  useEffect(() => {
    const subscription = userService.subscribe(() => getUser());

    if (!user) getUser();

    return () => {
      userService.unsubscribe(subscription);
    };
  }, []);

  const getUser = async () => {
    setUser(await userService.getUser$().finally(() => setLoading(false)));
  };

  const redirect = () => {
    Redirect(UserPaths.ApiLogin);
  };
  const isRegionalAdministrator = user?.Roles?.includes(
    RoleIds.RegionalAdministrator,
  );

  if (user?.IsRegionLead && isRegionalAdministrator) {
    return <Navigate to="/regional" />;
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div>
          {user ? (
            <Navigate to={from} />
          ) : (
            <div className="nhsuk-grid-row">
              <div className="nhsuk-grid-column-two-thirds">
                <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--l">
                  <h1 className="nhsuk-fieldset__heading">
                    Welcome to Record a Vaccination Service (RAVS)
                  </h1>
                </legend>
                <p>You are not signed in.</p>
                <Button onClick={redirect}>Log In</Button>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
