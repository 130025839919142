export enum Cookie {
  Name = "ravs-cookie-consent",
  Expiry = 365, //days
}

export enum CookiePolicyAnalyticsPageNames {
  PrimaryCategory = "cookie-policy",
}

export enum CookieConfirmationAnalyticsPageNames {
  PrimaryCategory = "cookie-confirmation",
}

export enum CookiePolicyPageTitles {
  CookiePolicy = "Cookie policy",
  CookieConfirmation = "Cookie confirmation",
}
