import { appAxios } from "../../../_shared/axios";

export class OktaService {
  private basePath = "api/okta";

  async checkOktaUserExistence$(email: string): Promise<boolean> {
    return (
      await appAxios.get(`${this.basePath}/user/exists`, {
        params: { email },
      })
    ).data;
  }

  static get instance() {
    return useOktaService;
  }
}

const useOktaService = new OktaService();

export default useOktaService;
