import React, { FC, useState } from "react";
import { Formik, Field, Form, FormikErrors } from "formik";
import { Radios, Button } from "nhsuk-react-components";
import { SelectedDates } from "../user/pages/Reports";
import { dateSelectionValidationSchema } from "./reports.validation";
import FormikErrorSummary from "./ErrorSummary";
import DateRangeInput from "./DateRangeInput";

export type DateInputValue = {
  day: string;
  month: string;
  year: string;
};

interface SelectedDatesInput {
  dateRangeOption: string;
  customDate: {
    fromDate: DateInputValue;
    toDate: DateInputValue;
  };
}

export const ReportDateSelection: FC<{
  setSelectedDate: React.Dispatch<React.SetStateAction<SelectedDates | null>>;
  handlePageChange: (isForward: boolean) => void;
  selectedDates: SelectedDates | null;
}> = ({ handlePageChange, setSelectedDate, selectedDates }) => {
  const formatDateToInputValue = (date: Date | null): DateInputValue => {
    if (!date) return { day: "", month: "", year: "" };
    return {
      day: String(date.getDate()).padStart(2, "0"),
      month: String(date.getMonth() + 1).padStart(2, "0"),
      year: String(date.getFullYear()),
    };
  };

  const initialValues: SelectedDatesInput = {
    dateRangeOption: selectedDates?.dateRangeOption || "",
    customDate: {
      fromDate: formatDateToInputValue(null),
      toDate: formatDateToInputValue(null),
    },
  };

  // If custom date range is already selected, set the initial values of the custom date inputs
  if (selectedDates?.dateRangeOption === "custom") {
    initialValues.customDate = {
      fromDate: formatDateToInputValue(selectedDates.fromDate),
      toDate: formatDateToInputValue(selectedDates.toDate),
    };
  }

  const dateValuesToDate = (values: DateInputValue): Date | null => {
    if (!values.day || !values.month || !values.year) return null;
    return new Date(`${values.year}-${values.month}-${values.day}`);
  };

  /**
   * Calculate dates based on the combination of selected option and custom dates
   */
  const calculateDates = (
    values: SelectedDatesInput,
  ): {
    fromDate: Date;
    toDate: Date;
  } => {
    const today = new Date();
    let fromDate: Date;
    let toDate: Date = today;
    toDate.setMinutes(0);
    toDate.setHours(0);
    toDate.setMilliseconds(0);

    switch (values.dateRangeOption) {
      case "yesterday":
        toDate.setDate(today.getDate() - 1);
        fromDate = toDate;
        break;
      case "last7days":
        fromDate = new Date(today);
        fromDate.setDate(today.getDate() - 6);
        break;
      case "last14days":
        fromDate = new Date(today);
        fromDate.setDate(today.getDate() - 13);
        break;
      case "last31days":
        fromDate = new Date(today);
        fromDate.setDate(today.getDate() - 30);
        break;
      case "custom":
        fromDate = dateValuesToDate(values.customDate.fromDate);
        toDate = dateValuesToDate(values.customDate.toDate);
        break;
      default:
        fromDate = new Date(today);
        break;
    }

    return {
      fromDate,
      toDate,
    };
  };

  const onSubmit = (values: SelectedDatesInput) => {
    const { fromDate, toDate } = calculateDates(values);

    setSelectedDate({
      dateRangeOption: values.dateRangeOption,
      fromDate,
      toDate,
    });
    handlePageChange(true);
  };

  const scrollToField = (fieldName: string) => {
    const element = document.getElementById(fieldName);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      element.focus();
    }
  };

  const filterErrors = (
    errors: FormikErrors<SelectedDatesInput>,
    values: SelectedDatesInput,
  ) => {
    // if custom date range is not selected, remove any errors related to the custom date range
    const filteredErrors = { ...errors };
    if (values.dateRangeOption !== "custom") {
      delete filteredErrors.customDate;
    }
    return Object.keys(filteredErrors).length === 0
      ? undefined
      : filteredErrors;
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={dateSelectionValidationSchema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, errors, values }) => (
          <Form>
            <FormikErrorSummary
              errors={filterErrors(errors, values)}
              onMessageClick={scrollToField}
            />
            <Field name="dateRangeOption">
              {({ field, meta }) => (
                <fieldset>
                  <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--xl">
                    <h1 className="nhsuk-fieldset__heading">Choose dates</h1>
                  </legend>
                  <div
                    className="nhsuk-hint mb-3"
                    id="record-a-service-h1-hint"
                  >
                    <p>Select a date range for your report</p>
                  </div>
                  <Radios
                    id="dateRangeOption"
                    name="dateRangeOption"
                    error={meta.touched && meta.error ? meta.error : ""}
                    {...field}
                  >
                    <Radios.Radio
                      value="today"
                      checked={values.dateRangeOption === "today"}
                    >
                      Today
                    </Radios.Radio>
                    <Radios.Radio
                      value="yesterday"
                      checked={values.dateRangeOption === "yesterday"}
                    >
                      Yesterday
                    </Radios.Radio>
                    <Radios.Radio
                      value="last7days"
                      checked={values.dateRangeOption === "last7days"}
                    >
                      Last 7 days (includes today)
                    </Radios.Radio>
                    <Radios.Radio
                      value="last14days"
                      checked={values.dateRangeOption === "last14days"}
                    >
                      Last 14 days (includes today)
                    </Radios.Radio>
                    <Radios.Radio
                      value="last31days"
                      checked={values.dateRangeOption === "last31days"}
                    >
                      Last 31 days (includes today)
                    </Radios.Radio>
                    <div className="nhsuk-radios__divider">or</div>
                    <Radios.Radio
                      value="custom"
                      checked={values.dateRangeOption === "custom"}
                    >
                      Select a custom date range up to 31 days
                    </Radios.Radio>
                  </Radios>
                </fieldset>
              )}
            </Field>

            {values.dateRangeOption === "custom" && (
              <div className="radios-dropdown">
                <Field name="customDate" component={DateRangeInput} />
              </div>
            )}

            <Button type="submit" disabled={isSubmitting}>
              Continue
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};
