import * as React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  DateToShortFormat,
  Redirect,
  ScrollToTop,
} from "../../_shared/shared.functions";
import { Paths } from "../../_shared/shared.enums";
import { Button } from "reactstrap";
import { SiteVaccines } from "./site-vaccines.models";
import {
  SiteVaccineBatchesPageNames,
  SiteVaccinesPageTitles,
  SiteVaccinesPaths,
} from "./site-vaccines.enums";
import { BatchDto } from "../batch/batch.models";
import useAnalytics from "../analytics/hooks/useAnalytics";
import useDocumentTitle from "../../_shared/hooks/useDocumentTitle";
import { Modal } from "reactstrap";
import siteVaccinesService from "./site-vaccines.service";

export default function SiteVaccineBatches() {
  useDocumentTitle(SiteVaccinesPageTitles.SiteVaccineBatchesList);
  const location = useLocation();
  const navigate = useNavigate();

  const redirect = () => {
    Redirect(Paths.Home);
  };

  const optionsStateData =
    location && location.state ? (location.state[0] as any) : redirect();
  const userSitesStateData =
    location && location.state ? (location.state[1] as any) : redirect();
  const siteVaccinesStateData =
    location && location.state
      ? (location.state[2] as SiteVaccines[])
      : redirect();
  const siteVaccineStateData =
    location && location.state
      ? (location.state[3] as SiteVaccines)
      : redirect();

  const [options] = React.useState(optionsStateData);
  const [userSites] = React.useState(userSitesStateData);
  const [siteVaccines, setSiteVaccines] = React.useState(
    siteVaccinesStateData as SiteVaccines[],
  );
  const [siteVaccine, setSiteVaccine] = React.useState(
    siteVaccineStateData as SiteVaccines,
  );

  const [isOpen, setIsOpen] = React.useState(false);
  const [adobeAnalyticsBatchReactivated, setAdobeAnalyticsBatchReactivated] =
    React.useState(false);
  const [modalBatch, setModalBatch] = React.useState(null as BatchDto);
  const [siteVaccineBatches, setSiteVaccineBatches] = React.useState(
    siteVaccine?.SiteVaccineBatches as BatchDto[],
  );

  useAnalytics([
    "service",
    SiteVaccineBatchesPageNames.PrimaryCategory,
    SiteVaccineBatchesPageNames.SubCategory1,
    adobeAnalyticsBatchReactivated
      ? SiteVaccineBatchesPageNames.SubCategory2
      : undefined,
    adobeAnalyticsBatchReactivated
      ? SiteVaccineBatchesPageNames.SubCategory3
      : undefined,
  ]);

  const showModal = (e, svb: BatchDto) => {
    e.preventDefault();
    setModalBatch(svb);
    setIsOpen(true);
  };

  const hideModal = () => {
    setModalBatch(null);
    setIsOpen(false);
  };

  const reactivateBatch = async (svb: BatchDto) => {
    svb.DepletedDate = "";
    var returnedResult =
      await siteVaccinesService.reactivateSiteVaccineBatch$(svb);

    setAdobeAnalyticsBatchReactivated(true);
    setModalBatch(null);
    navigate(SiteVaccinesPaths.SiteVaccineBatchesList, {
      state: [
        options,
        options?.Sites,
        returnedResult.SitesVaccines,
        returnedResult.SitesVaccines.find(
          (sv) => sv.SiteVaccineId === siteVaccine.SiteVaccineId,
        ),
      ],
    });
    hideModal();
  };

  const doseAmount = options?.Vaccines?.find(
    (v) => v.VaccineId === siteVaccine?.VaccineId,
  )?.DoseAmount;

  return (
    <>
      {location && location.state ? (
        <>
          <div className="nhsuk-back-link">
            <Link
              className="nhsuk-back-link__link"
              to={{ pathname: SiteVaccinesPaths.SiteVaccinesList }}
              state={[null, [] as SiteVaccines[]]}
            >
              <svg
                className="nhsuk-icon nhsuk-icon__chevron-left"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                aria-hidden="true"
                height="24"
                width="24"
              >
                <path d="M8.5 12c0-.3.1-.5.3-.7l5-5c.4-.4 1-.4 1.4 0s.4 1 0 1.4L10.9 12l4.3 4.3c.4.4.4 1 0 1.4s-1 .4-1.4 0l-5-5c-.2-.2-.3-.4-.3-.7z"></path>
              </svg>{" "}
              Back
            </Link>
          </div>

          <div className="nhsuk-grid-row mt-3">
            <div className="nhsuk-grid-column-full">
              <fieldset className="nhsuk-fieldset">
                <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--xl">
                  <h1 className="nhsuk-fieldset__heading">
                    {siteVaccine?.Vaccine}
                  </h1>
                </legend>

                <div className="row">
                  <div className="col-8">
                    <dl className="nhsuk-summary-list">
                      <div className="nhsuk-summary-list__row">
                        <dt className="nhsuk-summary-list__key">
                          <label className="nhsuk-label">
                            <b>Vaccine</b>
                          </label>
                        </dt>

                        <dd className="nhsuk-summary-list__value">
                          {siteVaccine?.VaccineProgram}
                        </dd>
                      </div>

                      <div className="nhsuk-summary-list__row">
                        <dt className="nhsuk-summary-list__key">
                          <label className="nhsuk-label">
                            <b>Dose</b>
                          </label>
                        </dt>

                        <dd className="nhsuk-summary-list__value">
                          {doseAmount} ml
                        </dd>
                      </div>

                      <div className="nhsuk-summary-list__row">
                        <dt className="nhsuk-summary-list__key">
                          <label className="nhsuk-label">
                            <b>Site</b>
                          </label>
                        </dt>

                        <dd className="nhsuk-summary-list__value">
                          {siteVaccine?.Site}
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
                <h2>{siteVaccine?.VaccineProgram}</h2>
              </fieldset>

              <div>
                <Link
                  to={{ pathname: SiteVaccinesPaths.AddBatch }}
                  state={[
                    options,
                    userSites,
                    siteVaccines,
                    {
                      Site: siteVaccine?.Site,
                      Code: siteVaccine.Code,
                      VaccineProgramId: siteVaccine?.VaccineProgramId,
                      VaccineProgram: siteVaccine?.VaccineProgram,
                      VaccineId: siteVaccine?.VaccineId,
                      Vaccine: siteVaccine?.Vaccine,
                    } as BatchDto,
                    siteVaccine?.SiteVaccineBatches,
                    {
                      IsAddBatch: true,
                      IsEditBatch: false,
                      IsAddEditBatchFromSiteVaccinesList: false,
                      IsAddEditBatchFromSiteBatchesList: true,
                    },
                    null,
                    siteVaccine,
                  ]}
                  className="nhsuk-button"
                >
                  Add batch
                </Link>
              </div>

              {siteVaccineBatches && siteVaccineBatches.length > 0 ? (
                <>
                  <table role="table" className="nhsuk-table-responsive">
                    <thead role="rowgroup" className="nhsuk-table__head">
                      <tr role="row">
                        <th role="columnheader" className="" scope="col">
                          Batch number
                        </th>
                        <th role="columnheader" className="" scope="col">
                          Expiry date
                        </th>
                        <th role="columnheader" className="" scope="col">
                          Status
                        </th>
                        <th role="columnheader" className="" scope="col">
                          Depleted date
                        </th>
                        <th role="columnheader" className="" scope="col">
                          {" "}
                        </th>
                      </tr>
                    </thead>

                    <tbody
                      key="SiteVaccineBatchesTableBody"
                      className="nhsuk-table__body"
                    >
                      {siteVaccineBatches.map((svb, index) => (
                        <tr
                          key={index + "SiteVaccineBatchesTabletr"}
                          role="row"
                          className="nhsuk-table__row"
                        >
                          <td
                            key={index + "SiteVaccineBatchesTabletdBatchNo"}
                            role="cell"
                            className="nhsuk-table__cell"
                          >
                            <React.Fragment
                              key={
                                index +
                                "FragmentSiteVaccineBatchesTabletdBatchNo"
                              }
                            >
                              <>
                                <span
                                  key={
                                    index +
                                    "SpanHeader1SiteVaccineBatchesTabletdBatchNo"
                                  }
                                  className="nhsuk-table-responsive__heading"
                                >
                                  Batch number
                                </span>
                                {svb.BatchNumber.toUpperCase()}
                              </>
                            </React.Fragment>
                          </td>

                          <td
                            key={index + "SiteVaccineBatchesTabletdExpiry"}
                            role="cell"
                            className="nhsuk-table__cell"
                          >
                            <React.Fragment
                              key={
                                index +
                                "FragmentSiteVaccineBatchesTabletdExpiry"
                              }
                            >
                              <>
                                <span
                                  key={
                                    index +
                                    "SpanHeader2SiteVaccineBatchesTabletdExpiry"
                                  }
                                  className="nhsuk-table-responsive__heading"
                                >
                                  Expiry date
                                </span>
                                {DateToShortFormat(svb.ExpiryDate)}
                              </>
                            </React.Fragment>
                          </td>

                          <td
                            key={index + "SiteVaccineBatchesTabletdStatus"}
                            role="cell"
                            className="nhsuk-table__cell"
                          >
                            <React.Fragment
                              key={
                                index +
                                "FragmentSiteVaccineBatchesTabletdStatus"
                              }
                            >
                              <>
                                <span
                                  key={
                                    index +
                                    "SpanHeaderSiteVaccineBatchesTabletdStatus"
                                  }
                                  className="nhsuk-table-responsive__heading"
                                >
                                  Status
                                </span>
                                {svb?.DepletedDate &&
                                new Date(svb.DepletedDate) >=
                                  new Date(
                                    new Date().setDate(
                                      new Date().getDate() - 1,
                                    ),
                                  ) &&
                                new Date(svb.ExpiryDate) >
                                  new Date(
                                    new Date().setDate(
                                      new Date().getDate() - 1,
                                    ),
                                  ) ? (
                                  <>
                                    <strong className="nhsuk-tag nhsuk-tag--grey">
                                      {" "}
                                      Pending
                                    </strong>
                                  </>
                                ) : (
                                  <>
                                    {(!svb?.DepletedDate &&
                                      new Date(svb.ExpiryDate) <
                                        new Date(
                                          new Date().setDate(
                                            new Date().getDate() - 1,
                                          ),
                                        )) ||
                                    (svb?.DepletedDate &&
                                      new Date(svb?.DepletedDate) <
                                        new Date(
                                          new Date().setDate(
                                            new Date().getDate() - 1,
                                          ),
                                        )) ? (
                                      <>
                                        <strong className="nhsuk-tag nhsuk-tag--white">
                                          {" "}
                                          Inactive
                                        </strong>
                                      </>
                                    ) : (
                                      <>
                                        <strong className="nhsuk-tag">
                                          {" "}
                                          Active
                                        </strong>
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            </React.Fragment>
                          </td>

                          <td
                            key={
                              index + "SiteVaccineBatchesTabletdDepletedDate"
                            }
                            role="cell"
                            className="nhsuk-table__cell"
                          >
                            <React.Fragment
                              key={
                                index +
                                "FragmentSiteVaccineBatchesTabletdDepletedDate"
                              }
                            >
                              <>
                                <span
                                  key={
                                    index +
                                    "SpanHeaderSiteVaccineBatchesTabletdDepletedDate"
                                  }
                                  className="nhsuk-table-responsive__heading"
                                >
                                  Depleted date
                                </span>
                                {DateToShortFormat(svb?.DepletedDate)}
                              </>
                            </React.Fragment>
                          </td>

                          <td
                            key={index + "SiteVaccineBatchesTabletdActionLinks"}
                            role="cell"
                            className="nhsuk-table__cell text-end"
                          >
                            <Link
                              id={"editBatchId" + { index }}
                              className="nhsuk-back-link__link"
                              to={{ pathname: SiteVaccinesPaths.EditBatch }}
                              state={[
                                options,
                                userSites,
                                siteVaccines,
                                svb,
                                siteVaccine?.SiteVaccineBatches,
                                {
                                  IsAddBatch: false,
                                  IsEditBatch: true,
                                  IsAddEditBatchFromSiteVaccinesList: false,
                                  IsAddEditBatchFromSiteBatchesList: true,
                                },
                                null,
                                siteVaccine,
                              ]}
                            >
                              Edit batch
                            </Link>
                            <br></br>
                            <br></br>
                            {(svb.DepletedDate ||
                              new Date(svb.DepletedDate) >
                                new Date(
                                  new Date().setDate(new Date().getDate() - 1),
                                )) &&
                            new Date(svb.ExpiryDate) >
                              new Date(
                                new Date().setDate(new Date().getDate() - 1),
                              ) ? (
                              <>
                                <Link
                                  id={"reactivateBatchId" + { index }}
                                  className="nhsuk-back-link__link"
                                  to=""
                                  onClick={(e) => showModal(e, svb)}
                                >
                                  Reactivate batch
                                </Link>

                                {ReactivateBatchModalComponent(
                                  isOpen,
                                  hideModal,
                                  modalBatch,
                                  reactivateBatch,
                                )}
                              </>
                            ) : (
                              <>
                                {!(
                                  !svb.DepletedDate &&
                                  new Date(svb.ExpiryDate) >
                                    new Date(
                                      new Date().setDate(
                                        new Date().getDate() - 1,
                                      ),
                                    )
                                ) ? (
                                  <>
                                    <div></div>{" "}
                                  </>
                                ) : (
                                  <>
                                    <Link
                                      id={"depleteBatchId" + { index }}
                                      className="nhsuk-back-link__link"
                                      to={{
                                        pathname:
                                          SiteVaccinesPaths.DepleteBatch,
                                      }}
                                      state={[
                                        options,
                                        userSites,
                                        siteVaccines,
                                        svb,
                                        siteVaccine?.SiteVaccineBatches,
                                        {
                                          IsAddBatch: false,
                                          IsEditBatch: false,
                                        },
                                        {
                                          IsUnDepleteAction: false,
                                          IsDepleteAction: true,
                                        },
                                        siteVaccine,
                                      ]}
                                    >
                                      Deplete batch
                                    </Link>
                                  </>
                                )}
                              </>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </>
      ) : (
        redirect()
      )}
    </>
  );
}

function ReactivateBatchModalComponent(
  isOpen: boolean,
  hideModal: () => void,
  svb: BatchDto,
  reactivateBatch: (svb: BatchDto) => Promise<void>,
) {
  return (
    <Modal
      isOpen={isOpen}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      autoFocus
      backdrop="static"
      keyboard={false}
    >
      <div className="p-3">
        {svb && <h3>Reactivate batch {svb?.BatchNumber}?</h3>}

        <p>This batch will become active immediately.</p>

        <div>
          <Button
            type="button"
            className="nhsuk-button nhsuk-button--reverse float-left"
            onClick={hideModal}
          >
            Cancel
          </Button>
          <Button
            type="button"
            className="nhsuk-button float-end"
            onClick={() => reactivateBatch(svb)}
          >
            Reactivate
          </Button>
        </div>
      </div>
    </Modal>
  );
}
