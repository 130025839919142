import React, { createContext, useContext, useState, useEffect } from "react";
import userService from "./user.service";
import { User } from "./user.models";

export const UserContext = createContext(null);

export function useUser(): User {
  return useContext(UserContext);
}

export default function UserProvider({ children }) {
  const [user, setUser] = useState(userService.getUser() || {});

  useEffect(() => {
    const subscription = userService.subscribe(() => updateUser());

    if (!user) updateUser();

    return () => {
      userService.unsubscribe(subscription);
    };
  }, []);

  const updateUser = async () => {
    setUser(await userService.getUser$());
  };

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
}
