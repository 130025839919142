import React from "react";

export default function NhsErrorSummary({
  form,
  activeFields,
  customError = null,
}) {
  let touchedFieldNumber = 0;
  const errors = [];

  activeFields.forEach((field) => {
    const err = form.errors[field];
    if (err) errors.push({ field, message: err });
    if (form.getFieldMeta(field)?.touched) touchedFieldNumber++;
  });

  const scrollToField = (field) => {
    const element = document.querySelector(`[name="${field}"]`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <>
      {(errors.length && touchedFieldNumber === activeFields.length) ||
      customError ? (
        <div
          className="nhsuk-error-summary"
          aria-labelledby="error-summary-title"
          role="alert"
        >
          <h2 className="nhsuk-error-summary__title" id="error-summary-title">
            There is a problem
          </h2>
          <div className="nhsuk-error-summary__body">
            <ul className="nhsuk-list nhsuk-error-summary__list">
              {customError && (
                <li className="nhsuk-error-message">{customError}</li>
              )}
              {errors.map((e, i) => (
                <li
                  className="nhsuk-error-message"
                  key={i}
                  onClick={() => scrollToField(e.field)}
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                >
                  {e.message}
                </li>
              ))}
            </ul>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
}
