import React, { useEffect, useState, useMemo } from "react";
import AccessControl from "../../_shared/components/accessControl/AccessControl";
import { RoleIds } from "../user/user.enums";
import { User } from "../user/user.models";
import userManagementService from "../../_shared/services/user-management/userManagement.service";
import NhsTable from "../../_shared/components/NHSUK/NhsTable";
import { BackLink, Button } from "nhsuk-react-components";
import {
  LeadUserFormValues,
  RegionalUserPages,
} from "../user/pages/RegionalUserPage";
import { Loading } from "../../_shared/components/Loading";
import { initialValues } from "../user/pages/ManageUsersRoot";
import {
  toTitleCase,
  UserStatusConverter,
} from "../../_shared/shared.functions";

interface TransformedUser {
  Name: string;
  EmailAddress: string;
  Status: string;
}

interface TransformedUser {
  Name: string;
  EmailAddress: string;
  Status: string;
}
interface OrganisationOverviewProps {
  organisationId: number;
  organisationName: string;
  setCurrentPage: (page: RegionalUserPages) => void;
  setLeadUserFormValues: React.Dispatch<
    React.SetStateAction<LeadUserFormValues>
  >;
  setIsExistingOrganisation: React.Dispatch<React.SetStateAction<boolean>>;
  setAddLeadUserPageTitle: React.Dispatch<React.SetStateAction<string>>;
}

const fetchUsers = async (organisationId: number) => {
  try {
    return await userManagementService.getOrganisationUsersByRole$(
      Number(organisationId),
      RoleIds.LeadAdministrator,
    );
  } catch (err) {
    console.error("Error fetching users:", err);
    return [];
  }
};

const OrganisationOverview: React.FC<OrganisationOverviewProps> = ({
  organisationId,
  organisationName,
  setCurrentPage,
  setLeadUserFormValues,
  setIsExistingOrganisation,
  setAddLeadUserPageTitle,
}) => {
  const [organisationUsers, setOrganisationUsers] = useState<User[] | null>(
    null,
  );
  const [loading, setLoading] = useState(true);

  const headers = useMemo(
    () => [
      { displayName: "Name", key: "Name" },
      { displayName: "Email address", key: "EmailAddress" },
      { displayName: "Status", key: "Status" },
    ],
    [],
  );

  useEffect(() => {
    const loadUsers = async () => {
      setLoading(true);
      const users = await fetchUsers(organisationId);
      setOrganisationUsers(users);
      setLoading(false);
    };
    loadUsers();
  }, [organisationId]);

  const transformUserData = (users: User[]): TransformedUser[] => {
    return users.map((user) => ({
      Name: `${toTitleCase(user.FirstName)} ${toTitleCase(user.LastName)}`,
      EmailAddress: user.Email,
      Status: UserStatusConverter(user.LastLoginDate),
    }));
  };

  const transformedData = useMemo(() => {
    return organisationUsers ? transformUserData(organisationUsers) : [];
  }, [organisationUsers]);

  const onAddUser = () => {
    setAddLeadUserPageTitle("Add another user");
    setIsExistingOrganisation(true);
    setLeadUserFormValues(initialValues);
    setCurrentPage(RegionalUserPages.AddLeadUser);
  };

  return (
    <AccessControl requiredRoles={[RoleIds.RegionalAdministrator]}>
      <div className="nhsuk-width-container">
        <BackLink
          asElement="button"
          onClick={() => setCurrentPage(RegionalUserPages.RegionOverview)}
        >
          Back to all organisations
        </BackLink>

        <main className="nhsuk-main-wrapper" id="maincontent" role="main">
          <div className="nhsuk-grid-row">
            <div className="nhsuk-grid-column-two-thirds">
              <h1 className="nhsuk-heading-l">{organisationName}</h1>
              <p>
                You have invited this organisation to create an NHS Record a
                vaccination service account.
              </p>
            </div>
          </div>
          {loading ? (
            <Loading message="Loading added lead users" />
          ) : (
            <div className="nhsuk-grid-row">
              <div className="nhsuk-grid-column-full">
                {organisationUsers?.length > 0 && (
                  <NhsTable<TransformedUser>
                    tableCaption="Users"
                    headers={headers}
                    data={transformedData}
                  />
                )}
                <Button onClick={onAddUser}>Add another user</Button>
              </div>
            </div>
          )}
        </main>
      </div>
    </AccessControl>
  );
};

export default OrganisationOverview;
