import React, { useMemo } from "react";
import { string } from "yup";
import NhsYesNo from "../../../_shared/components/form/NhsYesNo";
import useForm from "./useForm";
import {
  Cookie,
  CookiePolicyAnalyticsPageNames,
  CookiePolicyPageTitles,
} from "./cookie.enums";
import { useCookie } from "./CookieProvider";
import { Redirect } from "../../../_shared/shared.functions";
import useAnalytics from "../../analytics/hooks/useAnalytics";
import useDocumentTitle from "../../../_shared/hooks/useDocumentTitle";

export default function CookiePolicy() {
  useAnalytics(["service", CookiePolicyAnalyticsPageNames.PrimaryCategory]);
  useDocumentTitle(CookiePolicyPageTitles.CookiePolicy);
  const { cookieConsent, updateCookie } = useCookie();
  const formSettings = useMemo(() => {
    return {
      Consent: {
        Value: cookieConsent,
        Validation: string().required("No option selected"),
      },
    };
  }, []);

  const { form } = useForm(formSettings, handleSubmit);

  function handleSubmit() {
    updateCookie(form.values["Consent"]);
    Redirect("/cookie/confirmation");
  }

  return (
    <div>
      <div className="nhsuk-u-reading-width">
        <h1>Cookie policy</h1>

        <h2>What are cookies?</h2>
        <p>
          Cookies are files saved on your phone, tablet or computer when you
          visit a website.
        </p>
        <p>
          They store information about how you use the website, such as the
          pages you visit.
        </p>
        <p>
          Cookies are not viruses or computer programs. They are very small so
          do not take up much space.
        </p>

        <h2>How we use cookies</h2>
        <p>We only use cookies to:</p>

        <ul>
          <li>make our website work</li>
          <li>
            measure how you use our website, such as which links you click on
            (analytics cookies), if you give us permission
          </li>
        </ul>

        <p>
          We do not use any other cookies, for example, cookies that remember
          your settings or cookies that help with health campaigns.
        </p>
        <p>
          We sometimes use tools on other organisations' websites to collect
          data or to ask for feedback. These tools set their own cookies.{" "}
        </p>

        <h2>Cookies that make our website work</h2>
        <p>We use cookies to keep our website secure and fast.</p>
      </div>

      <details className="nhsuk-details">
        <summary className="nhsuk-details__summary">
          <span className="nhsuk-details__summary-text">
            List of cookies that make our website work
          </span>
        </summary>
        <div className="nhsuk-details__text">
          <div className="nhsuk-table-responsive app-table-responsive--cookies">
            <table className="nhsuk-table">
              <caption className="nhsuk-table__caption nhsuk-u-visually-hidden">
                Cookie names and purposes
              </caption>
              <thead className="nhsuk-table__head">
                <tr className="nhsuk-table__row" role="row">
                  <th
                    className="nhsuk-table__header"
                    role="columnheader"
                    scope="col"
                  >
                    Cookie name
                  </th>
                  <th
                    className="nhsuk-table__header"
                    role="columnheader"
                    scope="col"
                  >
                    Purpose
                  </th>
                  <th
                    className="nhsuk-table__header"
                    role="columnheader"
                    scope="col"
                  >
                    Expiry
                  </th>
                </tr>
              </thead>
              <tbody className="nhsuk-table__body">
                <tr className="nhsuk-table__row" role="row">
                  <td
                    className="nhsuk-table__cell"
                    role="cell"
                    data-label="Cookie name"
                  >
                    {Cookie.Name}
                  </td>
                  <td
                    className="nhsuk-table__cell"
                    role="cell"
                    data-label="Purpose"
                  >
                    Remembers if you used our cookies banner
                  </td>
                  <td
                    className="nhsuk-table__cell"
                    role="cell"
                    data-label="Expiry"
                  >
                    When you close the browser (if you do not use the banner) or
                    1 year (if you use the banner)
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </details>

      <div className="nhsuk-u-reading-width">
        <h2>Cookies that measure website use</h2>
        <p>
          We also like to use analytics cookies. These cookies store anonymous
          information about how you use our website, such as which pages you
          visit or what you click on.
        </p>
      </div>

      <details className="nhsuk-details">
        <summary className="nhsuk-details__summary">
          <span className="nhsuk-details__summary-text">
            List of cookies that measure website use
          </span>
        </summary>
        <div className="nhsuk-details__text">
          <div className="nhsuk-table-responsive app-table-responsive--cookies">
            <table className="nhsuk-table">
              <caption className="nhsuk-table__caption nhsuk-u-visually-hidden">
                Cookie names and purposes
              </caption>
              <thead className="nhsuk-table__head">
                <tr className="nhsuk-table__row" role="row">
                  <th
                    className="nhsuk-table__header"
                    role="columnheader"
                    scope="col"
                  >
                    Cookie name
                  </th>
                  <th
                    className="nhsuk-table__header"
                    role="columnheader"
                    scope="col"
                  >
                    Purpose
                  </th>
                  <th
                    className="nhsuk-table__header"
                    role="columnheader"
                    scope="col"
                  >
                    Expiry
                  </th>
                </tr>
              </thead>
              <tbody className="nhsuk-table__body">
                <tr className="nhsuk-table__row" role="row">
                  <td
                    className="nhsuk-table__cell"
                    role="cell"
                    data-label="Cookie name"
                  >
                    AMCV_#
                  </td>
                  <td
                    className="nhsuk-table__cell"
                    role="cell"
                    data-label="Purpose"
                  >
                    Used by Adobe Analytics. Tells us if you've used our site
                    before.
                  </td>
                  <td
                    className="nhsuk-table__cell"
                    role="cell"
                    data-label="Expiry"
                  >
                    2 years
                  </td>
                </tr>
                <tr className="nhsuk-table__row" role="row">
                  <td
                    className="nhsuk-table__cell"
                    role="cell"
                    data-label="Cookie name"
                  >
                    AMCVS_#AdobeOrg
                  </td>
                  <td
                    className="nhsuk-table__cell"
                    role="cell"
                    data-label="Purpose"
                  >
                    Used by Adobe Analytics. Tells us how you use our site.
                  </td>
                  <td
                    className="nhsuk-table__cell"
                    role="cell"
                    data-label="Expiry"
                  >
                    When you close the browser
                  </td>
                </tr>
                <tr className="nhsuk-table__row" role="row">
                  <td
                    className="nhsuk-table__cell"
                    role="cell"
                    data-label="Cookie name"
                  >
                    com.adobe.reactor.dataElementCookiesMigrated
                  </td>
                  <td
                    className="nhsuk-table__cell"
                    role="cell"
                    data-label="Purpose"
                  >
                    Used by Adobe Analytics. Includes data elements set to
                    capture usage of our website.
                  </td>
                  <td
                    className="nhsuk-table__cell"
                    role="cell"
                    data-label="Expiry"
                  >
                    No expiry
                  </td>
                </tr>
                <tr className="nhsuk-table__row" role="row">
                  <td
                    className="nhsuk-table__cell"
                    role="cell"
                    data-label="Cookie name"
                  >
                    demdex
                  </td>
                  <td
                    className="nhsuk-table__cell"
                    role="cell"
                    data-label="Purpose"
                  >
                    Used by Adobe Analytics. Allows cross-domain analytics of
                    NHS properties.
                  </td>
                  <td
                    className="nhsuk-table__cell"
                    role="cell"
                    data-label="Expiry"
                  >
                    180 days
                  </td>
                </tr>
                <tr className="nhsuk-table__row" role="row">
                  <td
                    className="nhsuk-table__cell"
                    role="cell"
                    data-label="Cookie name"
                  >
                    s_cc
                  </td>
                  <td
                    className="nhsuk-table__cell"
                    role="cell"
                    data-label="Purpose"
                  >
                    Used by Adobe Analytics. Checks if cookies work in your web
                    browser.
                  </td>
                  <td
                    className="nhsuk-table__cell"
                    role="cell"
                    data-label="Expiry"
                  >
                    When you close the browser
                  </td>
                </tr>
                <tr className="nhsuk-table__row" role="row">
                  <td
                    className="nhsuk-table__cell"
                    role="cell"
                    data-label="Cookie name"
                  >
                    s_getNewRepeat
                  </td>
                  <td
                    className="nhsuk-table__cell"
                    role="cell"
                    data-label="Purpose"
                  >
                    Used by Adobe Analytics. Tells us if you've used our website
                    before.
                  </td>
                  <td
                    className="nhsuk-table__cell"
                    role="cell"
                    data-label="Expiry"
                  >
                    30 days
                  </td>
                </tr>
                <tr className="nhsuk-table__row" role="row">
                  <td
                    className="nhsuk-table__cell"
                    role="cell"
                    data-label="Cookie name"
                  >
                    s_ppn
                  </td>
                  <td
                    className="nhsuk-table__cell"
                    role="cell"
                    data-label="Purpose"
                  >
                    Used by Adobe Analytics. Tells us how you use our website by
                    reading the previous page you visited.
                  </td>
                  <td
                    className="nhsuk-table__cell"
                    role="cell"
                    data-label="Expiry"
                  >
                    1 day
                  </td>
                </tr>
                <tr className="nhsuk-table__row" role="row">
                  <td
                    className="nhsuk-table__cell"
                    role="cell"
                    data-label="Cookie name"
                  >
                    s_sq
                  </td>
                  <td
                    className="nhsuk-table__cell"
                    role="cell"
                    data-label="Purpose"
                  >
                    Used by Adobe Analytics. Remembers the last link you clicked
                    on.
                  </td>
                  <td
                    className="nhsuk-table__cell"
                    role="cell"
                    data-label="Expiry"
                  >
                    When you close the browser
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </details>
      <form onSubmit={form.handleSubmit}>
        <div className="nhsuk-u-reading-width">
          <p>
            We'll only use these cookies if you say it's OK. We'll use a cookie
            to save your settings.
          </p>

          <h3>Tell us if we can use analytics cookies</h3>

          <NhsYesNo
            name="Consent"
            formFields={formSettings}
            lableYes="Use cookies to measure my website use"
            lableNo="Do not use cookies to measure my website use"
            formik={form}
          ></NhsYesNo>
          <button
            onClick={handleSubmit}
            type="submit"
            className="nhsuk-button nhsuk-u-margin-bottom-0"
          >
            Save my cookie settings
          </button>
        </div>
      </form>
    </div>
  );
}
