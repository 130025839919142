import React from "react";
import { BooleanToYesNo } from "../../shared.functions";

export default function NhsYesNoSummary({
  name,
  formFields,
  formik,
  label = null,
  showChange = null,
  onChange = null,
}) {
  const value = formik?.values[name];
  return (
    <>
      {value && (
        <div className="nhsuk-summary-list__row">
          <dt className="nhsuk-summary-list__key">
            {label ?? formFields[name]?.Label}
          </dt>

          <dd className="nhsuk-summary-list__value">{BooleanToYesNo(value)}</dd>

          <dd className="nhsuk-summary-list__actions">
            {showChange && onChange && (
              <button className="nhs-link-button" onClick={onChange}>
                Change
              </button>
            )}
          </dd>
        </div>
      )}
    </>
  );
}
