import React, { useEffect, useState, useCallback } from "react";
import { useFormik, FormikHelpers } from "formik";
import NhsAutocompleteSuggestionsV2 from "../../_shared/components/form/nhs-autocomplete-input/NhsAutocompleteSuggestionsV2";
import NhsAutocompleteInputV2 from "../../_shared/components/form/nhs-autocomplete-input/NhsAutocompleteInputV2";
import { RegionalUserPages } from "../user/pages/RegionalUserPage";
import { BackLink, Button, ErrorSummary } from "nhsuk-react-components";
import regionalService from "../../_shared/services/regional/regional.service";
import { Loading } from "../../_shared/components/Loading";
import organisationService from "../../_shared/services/organisation/organisation.service";
import { User } from "../user/user.models";
import { scrollToElementId, toTitleCase } from "../../_shared/shared.functions";
import { object, string } from "yup";
import { NhsdOrganisation } from "../../_shared/shared.models";

interface RegionalFindOrganisationProps {
  setCurrentPage: React.Dispatch<React.SetStateAction<RegionalUserPages>>;
  setSelectedOrganisation: React.Dispatch<
    React.SetStateAction<NhsdOrganisation | null>
  >;
  selectedOrganisation: NhsdOrganisation | null;
  currentUser: User;
}

interface AutocompleteOption {
  name: string;
  value: string;
}

const ERROR_MESSAGES = {
  EMPTY_SEARCH: "Search for an organisation",
  SELECT_ORGANISATION: "Select an organisation from the search",
  ORG_ALREADY_ADDED: "You have already added this organisation to your region",
  ORG_ALREADY_ADDED_REGION:
    "The {regionName} region has already added this organisation",
};

const RegionalFindOrganisation: React.FC<RegionalFindOrganisationProps> = ({
  setCurrentPage,
  setSelectedOrganisation,
  selectedOrganisation,
  currentUser,
}) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [filteredOptions, setFilteredOptions] = useState<AutocompleteOption[]>(
    [],
  );
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isTyping, setIsTyping] = useState<boolean>(false);
  const [fetchedData, setFetchedData] = useState<NhsdOrganisation[]>([]);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [hoveredField, setHoveredField] = useState<string | null>(null);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [userSummaryErrors, setUserSummaryErrors] = useState<string | null>(
    null,
  );

  useEffect(() => {
    setSelectedOrganisation(null);
  }, [setSelectedOrganisation]);

  const clearErrors = () => {
    setErrorMsg(null);
    setUserSummaryErrors(null);
  };

  const formik = useFormik({
    initialValues: {
      organisation: "",
    },
    validationSchema: object().shape({
      organisation: string().required("Search for an organisation"),
    }),
    validate: () => {
      if (formik.errors.organisation != null) {
        setUserSummaryErrors(formik.errors.organisation);
      }
    },
    onSubmit: async (
      values: { organisation: string },
      actions: FormikHelpers<{ organisation: string }>,
    ) => {
      if (values.organisation === "") {
        setErrorMsg(ERROR_MESSAGES.EMPTY_SEARCH);
        setUserSummaryErrors(ERROR_MESSAGES.EMPTY_SEARCH);
        return;
      }

      if (selectedOrganisation === null) {
        setErrorMsg(ERROR_MESSAGES.SELECT_ORGANISATION);
        setUserSummaryErrors(ERROR_MESSAGES.SELECT_ORGANISATION);
        return;
      }

      if (errorMsg !== null) {
        return;
      }

      setCurrentPage(RegionalUserPages.OrganisationSummary);
    },
  });

  const fetchOrganisations = useCallback(async () => {
    if (inputValue.trim().length > 2 && isTyping) {
      setIsLoading(true);
      try {
        const results = await regionalService.searchOrganisations$(
          inputValue.toUpperCase(),
        );
        const options = results.map((org) => ({
          name: `${toTitleCase(org.Name)}, ${org.PostCode.toUpperCase()}, (${org.OrgId})`,
          value: org.OrgId,
        }));
        setFetchedData(results);
        setFilteredOptions(options);
        setIsMenuOpen(true);
      } catch (err) {
        console.error("Failed to fetch organisations from ORD", err);
      } finally {
        setIsLoading(false);
      }
    } else {
      setFilteredOptions([]);
      setIsMenuOpen(false);
    }
  }, [inputValue, isTyping]);

  useEffect(() => {
    fetchOrganisations();
  }, [fetchOrganisations]);

  const handleCustomChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setIsSubmitted(false);
    setSelectedOrganisation(null);
    clearErrors();

    setInputValue(value);
    setIsTyping(true);
    formik.setFieldValue("organisation", value);
  };

  const handleSuggestionClick = async (
    name: string,
    data: AutocompleteOption,
  ) => {
    clearErrors();
    setInputValue(data.name);
    setIsMenuOpen(false);
    setIsTyping(false);
    const foundOption = filteredOptions.find(
      (option) => option.name === data.name,
    );
    const selectedOption = fetchedData.find(
      (org) => org.OrgId === foundOption?.value,
    );
    setSelectedOrganisation(selectedOption || null);
    formik.setFieldValue(name, data.name);

    if (selectedOption) {
      const organisationInfo = await organisationService.checkOrganisation$(
        selectedOption.OrgId,
      );
      if (organisationInfo.Exists) {
        const message =
          organisationInfo.Region.Id === currentUser.Region.Id
            ? ERROR_MESSAGES.ORG_ALREADY_ADDED
            : ERROR_MESSAGES.ORG_ALREADY_ADDED_REGION.replace(
                "{regionName}",
                organisationInfo.Region.Name,
              );
        setErrorMsg(message);
        setUserSummaryErrors(message);
      }
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="nhsuk-grid-row">
        <div className="nhsuk-grid-column-two-thirds">
          {userSummaryErrors && isSubmitted && (
            <ErrorSummary id="formErrors">
              <ErrorSummary.Title>There is a problem</ErrorSummary.Title>
              <ErrorSummary.Body>
                <ErrorSummary.List>
                  <ErrorSummary.Item>
                    <button
                      className={`anchor-style ${hoveredField === "organisation" ? "hovered-color" : "error-color"}`}
                      onMouseEnter={() => setHoveredField("organisation")}
                      onMouseLeave={() => setHoveredField(null)}
                      onClick={() => scrollToElementId("organisation")}
                    >
                      {userSummaryErrors}
                    </button>
                  </ErrorSummary.Item>
                </ErrorSummary.List>
              </ErrorSummary.Body>
            </ErrorSummary>
          )}
        </div>
        <div className="nhsuk-grid-column-two-thirds">
          <BackLink
            asElement="button"
            onClick={() => setCurrentPage(RegionalUserPages.RegionOverview)}
          >
            Back
          </BackLink>
          <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--xl nhsuk-u-margin-bottom-7">
            <h1 className="nhsuk-fieldset__heading">
              Find an organisation to invite
            </h1>
          </legend>

          <NhsAutocompleteInputV2
            style={{ marginBottom: "0 !important" }}
            label={"NHS organisation or community pharmacy"}
            hint={"Search by name or enter full ODS code"}
            name="organisation"
            formik={formik}
            handleCustomChange={handleCustomChange}
            customErrorMsg={errorMsg}
          />

          {isLoading && (
            <div className="nhsuk-u-margin-top-5">
              <Loading message="Searching for organisation" />
            </div>
          )}

          {isMenuOpen && !isLoading && (
            <NhsAutocompleteSuggestionsV2
              inputValue={formik.values.organisation}
              name="organisation"
              suggestions={filteredOptions}
              handleOnClick={handleSuggestionClick}
            />
          )}

          <Button
            type="submit"
            className="nhsuk-button nhsuk-u-margin-top-5"
            data-module="nhsuk-button"
            onClick={() => setIsSubmitted(true)}
          >
            Continue
          </Button>
        </div>
      </div>
    </form>
  );
};

export default RegionalFindOrganisation;
