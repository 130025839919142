import React from "react";
import { PageTitles } from "../../_shared/shared.enums";
import useAnalytics from "../analytics/hooks/useAnalytics";
import useDocumentTitle from "../../_shared/hooks/useDocumentTitle";

export default function HelpAndGuidance() {
  useAnalytics(["service", "help-guidance"]);
  useDocumentTitle(PageTitles.HelpAndGuidance);

  return (
    <div>
      <h1>Help and guidance</h1>
      <p>
        For guidance information, please click on the link:{" "}
        <a
          className="nhsuk-link"
          href="https://guide.ravs.england.nhs.uk/"
          target="blank"
        >
          guide.ravs.england.nhs.uk
        </a>
      </p>
    </div>
  );
}
