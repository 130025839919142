export const APP_BASE_URL = window.location.origin;

export const MILISECONDS_IN_ONE_DAY = 86400000;

export const REQUIRED_MESSAGE = "Required";

export const PROGRESS_ICONS_FILL = {
  FillGrey: { fill: "#D3D3D3" },
  FillBlack: { fill: "#212b32" },
  FillGreen: { fill: "green" },
};

export const ORGANISATION_LEAD_ADMINS_RETURN_LIMIT = 5;
