import React from "react";
interface LoadingProps {
  message?: string;
}

export const Loading: React.FC<LoadingProps> = ({ message }) => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
      <div className="mt-3">{message || "Loading..."}</div>
    </div>
  );
};
