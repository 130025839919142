import { Segments } from "../../components/analytics/analytics.models";
import useAnalytics from "../../components/analytics/hooks/useAnalytics";
import useDocumentTitle from "./useDocumentTitle";

export default function usePageSettings(segments: Segments) {
  let title = "RAVS";
  if (segments?.[1]) {
    title = segments[1].replace("-", " ") + " - " + title;
    title = title.charAt(0).toUpperCase() + title.slice(1);
  }

  if (segments?.[2]) {
    title = segments[2].replace("-", " ") + " - " + title;
    title = title.charAt(0).toUpperCase() + title.slice(1);
  }
  useAnalytics(segments);
  useDocumentTitle(title);
}
