import { appAxios } from "../../_shared/axios";
import { Patient, PatientSearchResponse } from "./patient.models";

export class PatientService {
  private basePath = "api/patient/";
  private cachedPatient: string;

  async get$(id: string): Promise<Patient> {
    return await appAxios.get(`${this.basePath}get?id=${id}`).then((res) => {
      this.cachedPatient = JSON.stringify(res.data);
      return res.data;
    });
  }

  async edit$(patient: Patient): Promise<Patient> {
    return await appAxios.post(`${this.basePath}edit`, patient).then((res) => {
      this.cachedPatient = JSON.stringify(res.data);
      return res.data;
    });
  }

  async add$(patient: Patient): Promise<Patient> {
    return await appAxios.post(`${this.basePath}add`, patient).then((res) => {
      return res.data;
    });
  }

  async nhsNumberSearch$(nhsNumber: string): Promise<PatientSearchResponse> {
    return await appAxios
      .get(`${this.basePath}nhsNumberSearch?nhsNumber=${nhsNumber}`)
      .then((res) => {
        return res.data;
      });
  }

  async detailsSearch$(search: Patient): Promise<PatientSearchResponse> {
    return await appAxios
      .post(`${this.basePath}detailsSearch`, search)
      .then((res) => {
        return res.data;
      });
  }

  async ravsSearchh$(search: Patient): Promise<Patient[]> {
    return await appAxios
      .post(`${this.basePath}ravsSearch`, search)
      .then((res) => {
        return res.data;
      });
  }

  async getCachedPatient(patientId): Promise<Patient> {
    if (patientId) {
      if (this.cachedPatient) {
        const patient = JSON.parse(this.cachedPatient);
        if (patient?.PatientId == patientId) return patient;
      }
      return this.get$(patientId);
    }
    throw new Error(`Argument is empty`);
  }

  static get instance() {
    return patientService;
  }
}

const patientService = new PatientService();

export default patientService;
