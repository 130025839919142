export enum SessionStorageKeys {
  User = "User",
  Consent = "Consent",
  Vaccination = "Vaccination",
  VaccinatorLocation = "VaccinatorLocation",
}

export enum PageType {
  Add = "add",
  Edit = "edit",
  Delete = "delete",
}

export enum RadioValues {
  true = "true",
  false = "false",
}

export enum Paths {
  Settings = "/settings",
  Home = "/home",
}

export enum Options {
  Statuses = "Statuses",
  Roles = "Roles",
}

export enum VaccineIds {
  PfizerCovidOriginalOmicronBA4to5 = 1,
  PfizerComirnatyOmicronXBBAge12Plus = 2,
  PfizerComirnatyOmicronXBB6monthsTo4Years = 3,
  PfizerComirnatyOmicronXBB5To11Years = 4,
  ModernaSpikevaxXBB = 5,
  FluenzTetraLAIV = 6,
  QuadrivalentInfluenzaVaccineQIVe = 7,
  QuadrivalentInfluvacSubunitTetraQIVe = 8,
  FlucelvaxTetraQIVc = 9,
  SupemtekQIVr = 10,
  FluadTetraaQIV = 11,
  SeqirusFluCellBasedQuadrivalentQIVc = 12,
  SeqirusFluAdjuvantedQuadrivalentaQIV = 13,
}

export enum Vaccines {
  //Pfizer
  PfizerCovid = "Comirnaty 30micrograms/0.3ml (Pfizer)",
  PfizerCovidChildren = "Comirnaty Children 5-11 years 10micrograms/0.2ml (Pfizer)",
  PfizerCovidOmicron = "Comirnaty Original/Omicron 15micrograms/0.3ml (Pfizer)",
  PfizerCovidChildren6monthsTo4years = "Comirnaty Children 6 months - 4 years 3micrograms/0.2ml (Pfizer)",
  PfizerCovidOriginalOmicronBA4to5 = "Comirnaty Original/Omicron BA.4-5 15micrograms/15micrograms/0.3ml (Pfizer)",
  PfizerComirnatyOmicronXBBAge12Plus = "Comirnaty Omicron XBB.1.5 30micrograms/0.3ml (Pfizer)",
  PfizerComirnatyOmicronXBB6monthsTo4Years = "Comirnaty XBB.1.5 6 months - 4 years 3micrograms/0.2ml (Pfizer)",
  PfizerComirnatyOmicronXBB5To11Years = "Comirnaty XBB.1.5 Children 5-11 years 10micrograms/0.3ml (Pfizer)",

  //AstraZeneca
  AstraZenecaCovid = "Vaxzevria 0.5ml (AstraZeneca)",

  //Moderna
  ModernaCovid = "Spikevax 0.1mg/0.5ml (Moderna)",
  ModernaCovidOmicron = "Spikevax Omicron 0.1mg/ml (Moderna)",
  ModernaCovidOriginalOmicronBa4to5 = "Spikevax Original/Omicron BA.4/BA.5 0.1mg/ml (Moderna)",
  ModernaSpikevaxXBB = "Spikevax XBB.1.5 0.1mg/1ml (Moderna)",

  //Novavax
  NovavaxCovid = "Nuvaxovid 5micrograms/0.5ml (Novavax)",

  //Sanofi Pasteur
  SanofiPasteurCovidBeta = "VidPrevtyn Beta 5micrograms/0.5ml (Sanofi Pasteur)",
}
export enum PageTitles {
  ContactUs = "Contact us",
  ReportIssue = "Report an issue",
  HelpAndGuidance = "Help and guidance",
}

export enum NotifyTemplateType {
  NewUserSignUpWithOktaActivation = 0,
  NewUserSignUpWithoutOktaActivation = 1,
  LeadUserWithoutOktaActivation = 2,
  LeadUserWithOktaActivation = 3,
}
