import { useEffect, useState } from "react";
import patientService from "../../patient/patient.service";
import { VaccineProgramIds } from "../vaccination.enums";
import { GetAge } from "../../../_shared/shared.functions";

export default function useCachedPatient(patientId) {
  const [patient, setPatient] = useState(null);
  const [dob, setDob] = useState(null);
  const [lastCovidVaccinationDate, setLastCovidVaccinationDate] =
    useState(null);
  const [age, setAge] = useState(null);

  useEffect(() => {
    if (patientId) {
      patientService.getCachedPatient(patientId).then((res) => {
        setPatient(res);
        setDob(new Date(res.DateOfBirth));
        setAge(GetAge(res.DateOfBirth));

        const lastCovidVaccination = res?.Vaccinations?.filter(
          (v) => v.VaccineProgramId == VaccineProgramIds.Covid,
        )?.[0];
        setLastCovidVaccinationDate(
          lastCovidVaccination
            ? new Date(lastCovidVaccination.VaccinationDate)
            : null,
        );
      });
    }
  }, [patientId]);
  return { patient, dob, lastCovidVaccinationDate, age };
}
