import { appAxios } from "../../../_shared/axios";
import { NhsdOrganisation } from "../../shared.models";

export class RegionalService {
  private basePath = "api/Regional/";

  async searchOrganisations$(
    searchTerm: string,
    isODSSearch: boolean = false,
  ): Promise<NhsdOrganisation[]> {
    return (
      await appAxios.get(
        `${this.basePath}SearchOrganisations?searchTerm=${searchTerm}&isODSSearch=${isODSSearch}`,
      )
    ).data.Organisations;
  }

  static get instance() {
    return regionalService;
  }
}

const regionalService = new RegionalService();

export default regionalService;
