import * as Yup from "yup";
import { DateInputValue } from "./ReportDateSelection";

const isValidDate = ({ day, month, year }: DateInputValue) => {
  if (!day || !month || !year) return false;
  const date = new Date(`${year}-${month}-${day}`);
  return (
    date.getFullYear() === parseInt(year, 10) &&
    date.getMonth() + 1 === parseInt(month, 10) &&
    date.getDate() === parseInt(day, 10)
  );
};

const joinPaths = (path: string, subPath: string) => {
  if (path === "") {
    return subPath;
  }
  return `${path}.${subPath}`;
};

export const dateSchema = Yup.object()
  .label("Date")
  .shape({
    // We don't do individual day, month, year validation here because we want to
    // validate the date as a whole.
    day: Yup.string(),
    month: Yup.string(),
    year: Yup.string(),
  })
  .test(
    "date-required",
    ({ label }) => `Enter ${label}`,
    function (value) {
      return !!value.day || !!value.month || !!value.year;
    },
  )
  /**
   * Say ‘[whatever it is] must include a [whatever is missing]’.
   * For example, ‘Date of birth must include a month’, ‘Date of birth must include a day and month’.
   */
  .test({
    name: "inner-fields-required",
    test(value, context) {
      // Return false if _some_ fields are missing.
      // Attach an error to the field(s) which have the issues
      const missingFields = ["day", "month", "year"].filter(
        (field) => !value[field],
      );
      let message: Yup.Message = () => "";
      if (missingFields.length === 1) {
        message = ({ label }) => `${label} must include a ${missingFields[0]}`;
      } else if (missingFields.length === 2) {
        message = ({ label }) =>
          `${label} must include a ${missingFields[0]} and ${missingFields[1]}`;
      } else {
        return true;
      }
      const errors = missingFields.map((field) => {
        return context.createError({
          message,
          path: joinPaths(context.path, field),
        });
      });
      return new Yup.ValidationError(errors);
    },
  })
  .test({
    name: "year-4-chars",
    test(value, context) {
      if (value.year && value.year.length < 4) {
        return context.createError({
          message: "Year must include 4 numbers",
          path: joinPaths(context.path, "year"),
        });
      }
      return true;
    },
  })
  .test({
    name: "inner-fields-valid",
    test(value, context) {
      const day = parseInt(value.day, 10);
      const month = parseInt(value.month, 10);
      const year = parseInt(value.year, 10);
      const message = ({ label }) => `${label} must be a real date`;
      const errors = [];
      if (value.day && (isNaN(day) || day < 1 || day > 31)) {
        errors.push(
          context.createError({
            message,
            path: joinPaths(context.path, "day"),
          }),
        );
      }
      if (value.month && (isNaN(month) || month < 1 || month > 12)) {
        errors.push(
          context.createError({
            message,
            path: joinPaths(context.path, "month"),
          }),
        );
      }
      if (value.year && isNaN(year)) {
        errors.push(
          context.createError({
            message,
            path: joinPaths(context.path, "year"),
          }),
        );
      }
      if (errors.length > 0) {
        return new Yup.ValidationError(errors);
      } else {
        return true;
      }
    },
  })
  .test(
    "date-valid",
    ({ label }) => `${label} must be a real date`,
    (value) => {
      return isValidDate(value as DateInputValue);
    },
  )
  .test(
    "not-future",
    ({ label }) => `${label} must be in the past`,
    function (value) {
      const dateValue = new Date(`${value.year}-${value.month}-${value.day}`);
      const today = new Date();
      dateValue.setHours(0, 0, 0, 0);
      today.setHours(0, 0, 0, 0);
      return dateValue <= today;
    },
  );

export const dateRangeSchema = Yup.object({
  fromDate: dateSchema.label("From date"),
  toDate: dateSchema.label("To date"),
})
  .test(
    "chronological-dates",
    "To date cannot be earlier than From date",
    function (value) {
      const { fromDate, toDate } = value;
      const from = new Date(
        `${fromDate.year}-${fromDate.month}-${fromDate.day}`,
      );
      const to = new Date(`${toDate.year}-${toDate.month}-${toDate.day}`);
      if (from && to) {
        return to >= from;
      }
    },
  )
  .test("max-range", "The date range must be within 31 days", function (value) {
    const { fromDate, toDate } = value;
    const from = new Date(`${fromDate.year}-${fromDate.month}-${fromDate.day}`);
    const to = new Date(`${toDate.year}-${toDate.month}-${toDate.day}`);
    if (from && to) {
      const diffTime = Math.abs(to.getTime() - from.getTime());
      const diffDays = diffTime / (1000 * 60 * 60 * 24);
      return diffDays <= 31;
    }
  });

export const dateSelectionValidationSchema = Yup.object({
  dateRangeOption: Yup.string().required("Please select a date range option"),
  customDate: Yup.object().when("dateRangeOption", {
    is: "custom",
    then: () => dateRangeSchema,
  }),
});
