import React from "react";
import ProgressCheckCircleIcon from "../../_shared/icons/ProgressCheckCircleIcon";
import "./Vaccination.scss";

export default function VaccinationProgress({ currentPage, pages }) {
  return (
    <div
      className="nhsuk-card mb-2"
      role="region"
      aria-label="Vaccination Progress"
    >
      <div className="nhsuk-card__content vaccination-progress p-4">
        {pages.map((o, i) => {
          const status =
            i < currentPage
              ? "completed"
              : i > currentPage
                ? "not completed"
                : "current step";
          const ariaLabel = `check mark, ${status}, ${o}`;

          return (
            <div key={i} className="row" role="listitem">
              <span
                className={
                  i === currentPage
                    ? "fw-bold"
                    : i < currentPage
                      ? "color_nhsuk-blue"
                      : ""
                }
              >
                <ProgressCheckCircleIcon
                  fillColor={{
                    fill:
                      i < currentPage
                        ? "#007f3b"
                        : i > currentPage
                          ? "#aeb7bd"
                          : "#212b32",
                  }}
                  ariaLabel={ariaLabel}
                />{" "}
                {o}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
}
