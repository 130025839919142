import React from "react";
import AccessControl from "../../../_shared/components/accessControl/AccessControl";
import { RoleIds } from "../user.enums";
import RegionOverview from "../../regional/RegionOverview";
import OrganisationOverview from "../../regional/OrganisationOverview";
import { useUser } from "../UserProvider";
import AddLeadUserForm from "../../regional/AddLeadUserForm";
import CheckAndSend from "../../regional/CheckAndSend";
import RegionalFindOrganisation from "../../regional/RegionalFindOrganisation";
import RegionalOrganisationSummary from "../../regional/RegionalOrganisationSummary";
import useDocumentTitle from "../../../_shared/hooks/useDocumentTitle";
import useAnalytics from "../../analytics/hooks/useAnalytics";
import { NhsdOrganisation } from "../../../_shared/shared.models";

export interface LeadUserFormValues {
  firstName: string;
  lastName: string;
  email: string;
}

export enum RegionalUserPages {
  RegionOverview = "Region overview",
  FindAnOrganisation = "Find an organisation to invite",
  OrganisationSummary = "Organisation Summary",
  OrganiatonOverview = "Organisational overview",
  AddLeadUser = "Add a lead user",
  CheckAndSend = "Check and send",
}

const initialValues: LeadUserFormValues = {
  firstName: "",
  lastName: "",
  email: "",
};

const getAnalyticsSegments = (currentPage: RegionalUserPages): string[] => {
  switch (currentPage) {
    case RegionalUserPages.RegionOverview:
      return [];
    case RegionalUserPages.FindAnOrganisation:
      return ["invite-organisation"];
    case RegionalUserPages.OrganisationSummary:
      return ["organisation-summary"];
    case RegionalUserPages.OrganiatonOverview:
      return ["organisation"];
    case RegionalUserPages.AddLeadUser:
      return ["organisation", "add-user"];
    case RegionalUserPages.CheckAndSend:
      return ["organisation", "add-user", "check"];
  }
};

const RegionalUserPage: React.FC = () => {
  const [selectedOrganisation, setSelectedOrganisation] =
    React.useState<NhsdOrganisation | null>(null);
  const user = useUser();
  const [currentPage, setCurrentPage] = React.useState<RegionalUserPages>(
    RegionalUserPages.RegionOverview,
  );
  const [leadUserFormValues, setLeadUserFormValues] =
    React.useState<LeadUserFormValues>(initialValues);
  const [focusField, setFocusField] = React.useState<string>("");
  const [isExistingOrganisation, setIsExistingOrganisation] =
    React.useState<boolean>(false);
  const [AddLeadUserPageTitle, setAddLeadUserPageTitle] =
    React.useState<string>("Add a user");

  useDocumentTitle(currentPage);
  useAnalytics(["regional", ...getAnalyticsSegments(currentPage)]);

  return (
    <div className="reginal-page-container">
      <AccessControl
        requiredRoles={[RoleIds.RegionalAdministrator]}
        matchAllRoles={false}
      >
        {currentPage === RegionalUserPages.RegionOverview && (
          <RegionOverview
            region={user.Region}
            setCurrentPage={setCurrentPage}
            setSelectedOrganisation={setSelectedOrganisation}
            setIsExistingOrganisation={setIsExistingOrganisation}
            setLeadUserFormValues={setLeadUserFormValues}
            setAddLeadUserPageTitle={setAddLeadUserPageTitle}
          />
        )}

        {currentPage === RegionalUserPages.AddLeadUser && (
          <AddLeadUserForm
            focusField={focusField}
            setFocusField={setFocusField}
            leadUserFormValues={leadUserFormValues}
            setCurrentPage={setCurrentPage}
            setLeadUserFormValues={setLeadUserFormValues}
            selectedOrganisation={selectedOrganisation}
            isExistingOrganisation={isExistingOrganisation}
            AddLeadUserPageTitle={AddLeadUserPageTitle}
          />
        )}

        {currentPage === RegionalUserPages.FindAnOrganisation && (
          <div>
            <RegionalFindOrganisation
              setCurrentPage={setCurrentPage}
              setSelectedOrganisation={setSelectedOrganisation}
              selectedOrganisation={selectedOrganisation}
              currentUser={user}
            />
          </div>
        )}

        {currentPage === RegionalUserPages.OrganisationSummary && (
          <div>
            <RegionalOrganisationSummary
              setCurrentPage={setCurrentPage}
              setSelectedOrganisation={setSelectedOrganisation}
              selectedOrganisation={selectedOrganisation}
            />
          </div>
        )}

        {currentPage === RegionalUserPages.OrganiatonOverview && (
          <OrganisationOverview
            setCurrentPage={setCurrentPage}
            organisationId={selectedOrganisation.Id}
            organisationName={selectedOrganisation.Name}
            setLeadUserFormValues={setLeadUserFormValues}
            setIsExistingOrganisation={setIsExistingOrganisation}
            setAddLeadUserPageTitle={setAddLeadUserPageTitle}
          />
        )}

        {currentPage === RegionalUserPages.CheckAndSend && (
          <CheckAndSend
            setFocusField={setFocusField}
            regionId={user.Region.Id}
            setCurrentPage={setCurrentPage}
            leadUserFormValues={leadUserFormValues}
            selectedOrganisation={selectedOrganisation}
            isExistingOrganisation={isExistingOrganisation}
            setSelectedOrganisation={setSelectedOrganisation}
          />
        )}
      </AccessControl>
    </div>
  );
};

export default RegionalUserPage;
