import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { emailValidationSchema } from "../../_shared/shared.validation";
import { Field, Form, Formik } from "formik";
import {
  BackLink,
  Button,
  ErrorSummary,
  TextInput,
} from "nhsuk-react-components";
import {
  LeadUserFormValues,
  RegionalUserPages,
} from "../user/pages/RegionalUserPage";
import { scrollToElementId, toTitleCase } from "../../_shared/shared.functions";
import { NhsdOrganisation } from "../../_shared/shared.models";

interface AddLeadUserFormProps {
  setCurrentPage: (page: RegionalUserPages) => void;
  leadUserFormValues: LeadUserFormValues;
  setLeadUserFormValues: React.Dispatch<
    React.SetStateAction<LeadUserFormValues>
  >;
  setFocusField: React.Dispatch<React.SetStateAction<string>>;
  focusField: string;
  selectedOrganisation?: NhsdOrganisation | null;
  isExistingOrganisation: boolean;
  AddLeadUserPageTitle?: string;
}

const AddLeadUserForm: React.FC<AddLeadUserFormProps> = ({
  setCurrentPage,
  leadUserFormValues,
  setLeadUserFormValues,
  setFocusField,
  focusField,
  selectedOrganisation,
  isExistingOrganisation,
  AddLeadUserPageTitle,
}) => {
  const [showErrorSummary, setShowErrorSummary] = useState(false);
  const [hoveredField, setHoveredField] = useState<string | null>(null);
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
  const [isTyping, setIsTyping] = useState<boolean>(true);

  const emailDuplicateCache: { [email: string]: boolean } = {};
  const emailDomainCache: { [email: string]: boolean } = {};

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .required("Enter first name")
      .max(50, "First name must be 50 characters or less"),
    lastName: Yup.string()
      .required("Enter last name")
      .max(50, "Last name must be 50 characters or less"),
    email: emailValidationSchema(false, emailDomainCache, emailDuplicateCache),
  });

  useEffect(() => {
    if (focusField) {
      scrollToElementId(focusField);
      setFocusField("");
    }
  }, [focusField, setFocusField]);

  const onSubmit = (values: LeadUserFormValues) => {
    setIsTyping(false);
    setLeadUserFormValues(values);
    setCurrentPage(RegionalUserPages.CheckAndSend);
  };

  const renderField = (name: string, label: string, width: number) => (
    <Field name={name}>
      {({ field, meta }) => (
        <TextInput
          {...field}
          id={name}
          label={label}
          error={meta.touched && meta.error ? meta.error : ""}
          width={width}
        />
      )}
    </Field>
  );

  useEffect(() => {
    if (showErrorSummary) {
      setFocusField("formErrors");
    }
  }, [showErrorSummary]);

  const handleCurrentPage = () => {
    const page = isExistingOrganisation
      ? RegionalUserPages.OrganiatonOverview
      : RegionalUserPages.OrganisationSummary;
    setCurrentPage(page);
  };
  return (
    <>
      {!isTyping && showErrorSummary && Object.keys(formErrors).length > 0 && (
        <ErrorSummary id="formErrors">
          <ErrorSummary.Title>There is a problem</ErrorSummary.Title>
          <ErrorSummary.Body>
            <ErrorSummary.List>
              {Object.keys(formErrors).map((fieldName, index) => (
                <ErrorSummary.Item key={index}>
                  <button
                    className={`anchor-style ${hoveredField === fieldName ? "hovered-color" : "error-color"}`}
                    onMouseEnter={() => setHoveredField(fieldName)}
                    onMouseLeave={() => setHoveredField(null)}
                    onClick={() => scrollToElementId(fieldName)}
                  >
                    {formErrors[fieldName]}
                  </button>
                </ErrorSummary.Item>
              ))}
            </ErrorSummary.List>
          </ErrorSummary.Body>
        </ErrorSummary>
      )}

      <BackLink asElement="button" onClick={handleCurrentPage}>
        Back
      </BackLink>
      <div className="nhsuk-width-container">
        <main className="nhsuk-main-wrapper" id="maincontent" role="main">
          <div className="nhsuk-grid-row">
            <div className="nhsuk-grid-column-two-thirds">
              <h1 className="nhsuk-heading-l">{AddLeadUserPageTitle}</h1>
              <p>
                Choose a user at {toTitleCase(selectedOrganisation.Name)} to
                receive instructions about creating an account.
              </p>
              <p>
                It’s their responsibility to set up and add their organisation’s
                users and vaccines.
              </p>

              <Formik
                initialValues={leadUserFormValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ isSubmitting, errors }) => {
                  setFormErrors(errors);
                  if (isSubmitting) {
                    setIsTyping(!isSubmitting);
                  }
                  return (
                    <Form>
                      <>
                        {renderField("firstName", "First name", 20)}
                        {renderField("lastName", "Last name", 20)}
                        {renderField("email", "NHS email address", 30)}
                      </>
                      <Button
                        type="submit"
                        className="nhsuk-button"
                        data-module="nhsuk-button"
                        disabled={isSubmitting}
                        onClick={() => {
                          setShowErrorSummary(true);
                          scrollToElementId("formErrors");
                        }}
                      >
                        Continue
                      </Button>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default AddLeadUserForm;
