import { useState, useEffect } from "react";
import React from "react";
import userService from "../user.service";
import { Navigate, useLocation } from "react-router-dom";
import { appAxios } from "../../../_shared/axios";

export default function UserOrganisation() {
  const [user, setUser] = useState(null);
  const [organisation, setOrganisation] = useState(null);
  const [organisations, setOrganisations] = useState(null);

  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  useEffect(() => {
    const subscription = userService.subscribe(() => getUser());

    getUser();

    return () => {
      userService.unsubscribe(subscription);
    };
  }, []);

  const getUser = async () => {
    setUser(await userService.getUser$());
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => setOrganisation(organisation), 5000);
    console.log(organisation);
    getOrganisations(organisation);
    return () => clearTimeout(timeOutId);
  }, [organisation]);

  const onOrganisationChange = (e) => {
    const value = e.target.value;
    if (value.length > 3) {
      setOrganisation(value);
    }
  };

  const getOrganisations = async (organisation: string) => {
    setOrganisations(
      await appAxios.get(
        `/api/user/organisations?organisation=${organisation}`,
      ),
    );
  };

  const renderOrganisations = () => {
    if (organisations?.length) {
      return (
        <ul>
          {organisations.map((org) => (
            <li key={org.Id} onClick={onOrganisationChange}>
              {" "}
              {org}{" "}
            </li>
          ))}
        </ul>
      );
    }
    return null;
  };

  const suggestionSelected = (value) => {
    setOrganisation(value);
  };

  return (
    <>
      {user?.OrganisationId ? (
        <Navigate to={from} />
      ) : (
        <div className="card mb-2">
          <div className="card-body">
            <h2>User Organisation</h2>
            <hr />

            <div className="alert alert-info mt-4" role="alert">
              More information is required about your account! Please search,
              select and save your organisation.
            </div>

            <div className="row">
              <div className="col-md-7">
                <label htmlFor="Organisation">Organisation</label>
                <input
                  type="text"
                  id="Organisation"
                  onChange={(event) => setOrganisation(event.target.value)}
                  placeholder="Enter 3 or more characters to search"
                  value={organisation}
                  className="form-control"
                />

                {renderOrganisations()}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
