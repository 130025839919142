import React from "react";
import { BackLink } from "nhsuk-react-components";
import OrganisationUserForm from "../../user-management/OrganisationUserForm";
import AccessControl from "../../../_shared/components/accessControl/AccessControl";
import { RoleIds } from "../user.enums";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import useDocumentTitle from "../../../_shared/hooks/useDocumentTitle";
import useAnalytics from "../../analytics/hooks/useAnalytics";
import { ManageUsersContext } from "./ManageUsersRoot";

const pageTitle = "Add user";

const ManageUsersAddPage: React.FC = () => {
  const navigate = useNavigate();
  useDocumentTitle(pageTitle);
  const { users, userFormValues, setUserFormValues } =
    useOutletContext<ManageUsersContext>();

  const location = useLocation();
  const focusField = location.state?.focusField;

  useAnalytics(["service", "manage-users", "add"]);

  return (
    <AccessControl
      requiredRoles={[RoleIds.LeadAdministrator]}
      matchAllRoles={false}
    >
      <div className="nhsuk-grid-row">
        <BackLink asElement="button" onClick={() => navigate("..")}>
          Back
        </BackLink>
        <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--xl nhsuk-u-margin-bottom-7">
          <h1 className="nhsuk-fieldset__heading">{pageTitle}</h1>
        </legend>

        <OrganisationUserForm
          userFormValues={userFormValues}
          initialFocusField={focusField}
          isEditing={false}
          users={users}
          onSubmit={async (values) => {
            setUserFormValues(values);
            navigate("./check");
          }}
          canDeactivateUsers={false}
        />
      </div>
    </AccessControl>
  );
};

export default ManageUsersAddPage;
